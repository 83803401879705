import React from 'react'
import { useContext } from 'react'
import { SessionContext } from '../context/SessionContext'
// import useSetDocument from './useSetDocument'
// import { IUser } from '../types/user'

const NotificationCounterContext = React.createContext(0)
// const NotificationCounterUpdateContext = React.createContext(() => alert())

export const useNotificationCounter = () => {
  return useContext(NotificationCounterContext)
}

// export const useNotificationCounterUpdate = () => {
//   return useContext(NotificationCounterUpdateContext)
// }

export const NotificationsCounterProvider = ({ children }: { children: any }) => {
  const { /* currentUser,*/ user } = useContext(SessionContext)
  const counter = user?.notificationCounter
  // const updateUser = useSetDocument<IUser>('user')

  // const setCounterToZero = () => {
  //   updateUser({ ...user, notificationCounter: 0 }, currentUser?.uid)
  // }
  return (
    <NotificationCounterContext.Provider value={counter || 0}>
      {/* <NotificationCounterUpdateContext.Provider value={setCounterToZero}> */}
      {children}
      {/* </NotificationCounterUpdateContext.Provider> */}
    </NotificationCounterContext.Provider>
  )
}
