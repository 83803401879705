import React from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Controller, ErrorOption, useForm } from 'react-hook-form'
import Button from '../../Button'
import TextInputWithIcon from '../../TextInputWithIcon'
import useStyles from '../login-styles'
import { yupResolver } from '@hookform/resolvers/yup'
import ChangePasswordValidator from '../../../validators/ChangePasswordValidator'
import icons from '../../../constants/icons'
import ColoredText from '../../CustomText/ColoredText'

export type FormValues = { currentPassword: string; newPassword1: string; newPassword2: string }

interface IProps {
  onSubmit: ({
    setError,
    reset,
  }: {
    setError: (key: string, type: string, message: string) => void
    reset: () => void
  }) => (params: FormValues) => void
  loading: boolean
}

const ChangePasswordForm = ({ onSubmit, loading }: IProps) => {
  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(ChangePasswordValidator),
  })

  const { t } = useTranslation()

  const styles = useStyles()
  return (
    <View>
      <Controller
        name={'currentPassword'}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputWithIcon
            icon={[icons.faIconStyle, 'key']}
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            secureTextEntry
            style={styles.textInput}
            placeholder={`${t('placeholder.userProfile.currentPassword')}`}
            autoCompleteType={'password'}
            autoCorrect={false}
            editable={!loading}
          />
        )}
      />

      <View style={styles.errorWrapper}>
        {errors?.currentPassword ? <ColoredText>{errors.currentPassword.message}</ColoredText> : null}
      </View>

      <Controller
        name={'newPassword1'}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputWithIcon
            icon={[icons.faIconStyle, 'lock']}
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            secureTextEntry
            style={styles.textInput}
            placeholder={`${t('placeholder.userProfile.newPassword')}`}
            autoCompleteType={'password'}
            autoCorrect={false}
            editable={!loading}
          />
        )}
      />

      <View style={styles.errorWrapper}>
        {errors?.newPassword1 ? <ColoredText>{errors.newPassword1.message}</ColoredText> : null}
      </View>

      <Controller
        name={'newPassword2'}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputWithIcon
            icon={[icons.faIconStyle, 'lock']}
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            secureTextEntry
            style={styles.textInput}
            placeholder={`${t('placeholder.userProfile.newPassword')}`}
            autoCompleteType={'password'}
            autoCorrect={false}
            editable={!loading}
          />
        )}
      />

      <View style={styles.errorWrapper}>
        {errors?.newPassword2 ? <ColoredText>{errors.newPassword2.message}</ColoredText> : null}
      </View>
      <Button
        style={styles.button}
        textStyle={styles.buttonText}
        onPress={handleSubmit(
          onSubmit({
            reset: reset,
            setError: (key, type, message) =>
              setError(key, {
                message: message,
                type: type,
              } as ErrorOption),
          })
        )}
        disabled={loading || !isDirty}
      >
        {t('button.userProfile.changePassword')}
      </Button>
    </View>
  )
}

export default ChangePasswordForm
