import React from 'react'
import { Platform, View } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Button from '../../Button'
import useStyles from '../login-styles'
import { yupResolver } from '@hookform/resolvers/yup'
import LoginValidator from '../../../validators/LoginValidator'
import icons from '../../../constants/icons'
import ColoredText from '../../CustomText/ColoredText'
import InputWithIcon from '../../InputWithIcon'
import InternalLink from '../../Router/InternalLink'
import DashbarLogin from '../../../assets/imgs/login/DashbarLogin'

interface IProps {
  onSubmit: (params: { email: string; password: string }) => void
  loading: boolean
}

const LoginForm = ({ onSubmit, loading }: IProps) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<{ email: string; password: string }>({
    resolver: yupResolver(LoginValidator),
  })
  const { t } = useTranslation()

  const styles = useStyles()

  return (
    <View>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputWithIcon
            icon={[icons.faIconStyle, 'user']}
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={`${t('placeholder.baseLogin.email')}`}
            style={styles.textInput}
            editable={!loading}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
        name={'email'}
      />

      <View style={styles.errorWrapper}>
        {errors.email ? <ColoredText style={{ color: 'black', padding: 5 }}>{errors.email.message}</ColoredText> : null}
      </View>

      <Controller
        control={control}
        name={'password'}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputWithIcon
            icon={[icons.faIconStyle, 'lock']}
            style={styles.textInput}
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            secureTextEntry
            placeholder={`${t('placeholder.baseLogin.password')}`}
            textContentType="password"
            editable={!loading}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
      />

      <View style={styles.errorWrapper}>
        {errors.password ? (
          <ColoredText style={{ color: 'black', padding: 5 }}>{errors.password.message}</ColoredText>
        ) : null}
      </View>
      <View
        style={{
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <InternalLink style={{ color: 'black' }} to={'/reset-password'} text={`${t('link.baseLogin.resetPassword')}`} />
      </View>
      <Button
        style={[styles.button, { paddingTop: Platform.OS === 'web' ? 7 : 11 }]}
        textStyle={styles.buttonText}
        onPress={handleSubmit(onSubmit)}
        disabled={loading}
      >
        {/* {t('button.baseLogin.login')} */}
        <DashbarLogin />
      </Button>
    </View>
  )
}

export default LoginForm
