import { View } from 'react-native'
import useStyles from './styles'
import NumberInput from '../../../../../../components/Input/NumberInput'
import React, { useContext, useEffect } from 'react'
import Button from '../../../../../../components/Button'
import { SessionContext } from '../../../../../../context/SessionContext'
import { storeSettings } from './funcs'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import MenuButtonSwitch from '../../../../components/MenuButtonSwitch'
import * as useDefaultStyles from '../../../../utils/styles'
import MenuTitle from '../../../../components/MenuTitle'

const WidgetSettings = () => {
  const { t } = useTranslation()
  const styles = useStyles()
  const defaultStyles = useDefaultStyles.default()
  const [isEnabled, setIsEnabled] = React.useState(false)
  const [_error, setError] = React.useState<string | null>(null)
  const [value, setValue] = React.useState(120)
  const { user, company } = useContext(SessionContext)

  const updateValue = (value: number) => {
    setValue(value)
    //console.log(value)
  }
  const toggleSwitch = () => {
    setIsEnabled(!isEnabled)
  }

  useEffect(() => {
    setIsEnabled(user?.preferences?.widget?.autoRefresh || false)
    setValue(user?.preferences?.widget?.interval ? +user?.preferences?.widget?.interval : 120)
  }, [])

  return (
    <View style={defaultStyles.settingsWrapper}>
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle
            title={`${t('settings.web.widgets.updates.title')}`}
            subtitle={`${t('settings.web.widgets.updates.subtitle')}`}
          />
        </View>
        <View style={defaultStyles.settingsOptionWrapper}>
          <MenuButtonSwitch
            text={isEnabled ? t('settings.widget.active') : t('settings.widget.inactive')}
            enabled={isEnabled}
            setEnabled={toggleSwitch}
            single
          />
        </View>
      </View>
      <View style={defaultStyles.settingsDivider} />
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle
            title={`${t('settings.web.widgets.intervall.title')}`}
            subtitle={`${t('settings.web.widgets.intervall.subtitle')}`}
          />
        </View>
        <View style={defaultStyles.settingsOptionWrapper}>
          <View style={styles.numberInputWrapper}>
            <NumberInput
              defaultValue={user?.preferences?.widget?.interval ? +user?.preferences?.widget?.interval : 120}
              disabled={!isEnabled}
              updateValue={updateValue}
            />
          </View>
        </View>
      </View>
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper} />
        <View style={defaultStyles.settingsOptionWrapper}>
          <Button
            onPress={async () => storeSettings({ autoRefresh: isEnabled, interval: value }, company?.bundle, setError)}
            textStyle={{ color: 'white' }}
            style={styles.button}
          >
            <>{`${t('settings.web.widgets.save')}`}</>
          </Button>
        </View>
      </View>
    </View>
  )
}

export default WidgetSettings
