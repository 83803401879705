import React, { useContext, useEffect } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { View } from 'react-native'
import useStyles from './components/styles'
import DisplayedNotificationsList from './components/DisplayedNotificationsList'
// import { useNotificationCounterUpdate } from '../../hooks/NotificationsCounterProvider'
import PageWrapper from '../../components/PageWrapper'
import { isEmpty } from 'lodash'
import Button from '../../components/Button'
import { useTranslation } from '../../hooks/useTranslation'
import LoadingScreen from '../../components/LoadingScreen'
import { useFirestore } from 'react-redux-firebase'

const NotificationsList = () => {
  const [isVisible, setisVisible] = React.useState(false)
  const { currentUser, shops, user } = useContext(SessionContext)
  const styles = useStyles()
  const { t } = useTranslation()
  // const setNotificationCountertoNull = useNotificationCounterUpdate()
  // setNotificationCountertoNull()
  const firestore = useFirestore()

  useEffect(() => {
    if (currentUser && currentUser.uid)
      firestore
        .collection(`user`)
        .doc(currentUser.uid)
        .set({ notificationCounter: 0, updatedAt: new Date() }, { merge: true })
  }, [currentUser])
  if (isEmpty(user)) {
    return <LoadingScreen text={`${t('loading.data')}`} />
  }
  if (shops === null) return <LoadingScreen text={`${t('loading.shops')}`} />

  const shopList: Array<string> = []
  Object.entries(shops || {}).map((shop) => shopList.push(shop[0]))
  return (
    <PageWrapper isScrollable={false} scrollViewStyle={{ height: '100%' }}>
      <View style={{ flexDirection: 'row' }}>
        <Button style={styles.button} onPress={() => setisVisible(true)}>
          <>{t('notifications.filter')}</>
        </Button>
      </View>
      <DisplayedNotificationsList user={user} shops={shopList} isVisible={isVisible} setisVisible={setisVisible} />
    </PageWrapper>
  )
}

export default NotificationsList
