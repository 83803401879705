import { useFirestore } from 'react-redux-firebase'
import { useCallback, useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { Themes } from '../../types/dashbar/theme'

const useStoreTheme = () => {
  const { currentUser } = useContext(SessionContext)
  const firestore = useFirestore()

  return useCallback(
    async (theme: Themes) => {
      await firestore.doc(`user/${currentUser?.uid}`).set({ preferences: { theme: theme } }, { merge: true })
    },
    [firestore]
  )
}

export default useStoreTheme
