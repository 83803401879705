import React, { useCallback, useState } from 'react'

import { useFirebase } from 'react-redux-firebase'
import useLoginReducer, { Types } from '../../../hooks/user/useLoginReducer'
import AuthError from '../../../utils/AuthError'

import ResetPasswordView from '../../../components/ResetPassword'

const ResetPassword = () => {
  const firebase = useFirebase()
  const [{ loading, alert }, dispatch] = useLoginReducer()
  const [resetPasswordState, setResetPasswordState] = useState<{
    success?: boolean
  }>({
    success: false,
  })

  const handleResetPassword = useCallback(
    ({ email }: { email: string }) => {
      dispatch({ type: Types.START_RESET })

      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setResetPasswordState({ success: true })
          dispatch({ type: Types.FINISH_RESET })
        })
        .catch(({ code }: { code: string }) => {
          const error = new AuthError(code)

          dispatch({ type: Types.ERROR_RESET, payload: { error } })
        })
    },
    [firebase, dispatch]
  )

  return (
    <ResetPasswordView
      {...{
        loading,
        success: resetPasswordState.success,
        alert,
        handleResetPassword,
      }}
    />
  )
}

export default ResetPassword
