import React from 'react'
import useStyles from './styles'
import { TouchableOpacity, View } from 'react-native'
import ColoredText from '../../../../../../../components/CustomText/ColoredText'
import Button from '../../../../../../../components/Button'
import { usePrimaryBackground } from '../../../../../../../hooks/useColors'
import { Image, ImageSource } from 'expo-image'

export interface IPlatformCardProps {
  isExternal?: boolean
  domain: string
  platform: Record<string, any>
  select?: (...args: Array<any>) => void
  image?: string | ImageSource | ImageSource[]
  buttonText?: string
  isSelected?: boolean
}

const PlatformCard = ({
  isExternal = false,
  domain,
  platform,
  image,
  select,
  buttonText = 'wählen',
  isSelected,
}: IPlatformCardProps) => {
  const styles = useStyles()
  const primaryBackground = usePrimaryBackground()
  if (!domain || !platform || !image) {
    return null
  }

  return (
    <TouchableOpacity
      onPress={() => (select ? select(domain, image, platform, isExternal) : null)}
      style={[styles.extensionCard, isSelected && { backgroundColor: primaryBackground }]}
    >
      <View style={styles.headerWrapper}>
        <Image
          {...{
            width: 50,
            height: 50,
            resizeMode: 'stretch',
            source: image,
            style: {
              width: 50,
              height: 50,
              borderRadius: isExternal === true ? 0 : 50,
            },
          }}
        />
        <View style={styles.descriptionWrapper}>
          <ColoredText style={styles.titleText}>{`${domain}`}</ColoredText>
          {!isExternal && <ColoredText style={styles.descriptionText}>{platform.name}</ColoredText>}
        </View>
        <View style={styles.actionWrapper}>
          <Button style={styles.button} disabled textStyle={{ color: 'white', fontSize: 12 }}>
            {`${buttonText}`}
          </Button>
        </View>
      </View>
    </TouchableOpacity>
  )
}

export default PlatformCard
