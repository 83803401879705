import { processFontFamily } from 'expo-font'
import * as React from 'react'
import Svg, { SvgProps, G, Path, Ellipse, Text, TSpan } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */
const NoInvoiceSVG = (props: SvgProps) => (
  <Svg width={600} height={400} {...props}>
    <G fill="none" fillRule="evenodd">
      <Path
        fill="#FFB294"
        d="m403.45 60.751-1.405 17.343h17.263c-1.331-6.454-1.997-11.28-1.997-14.48 0-4.798 6.845-22.048 8.671-27.238 1.826-5.19-8.671-16.786-18.958-14.178-10.287 2.608-11.45 12.92-11.45 17.047 0 4.127.66 15.345 2.9 18.426 1.492 2.053 3.151 3.08 4.976 3.08Z"
      />
      <Path stroke="#A86F59" strokeLinecap="round" strokeWidth={1.1} d="M401 52.707c1.402-.732 2.82-.763 4.252-.096" />
      <Path
        fill="#000"
        fillOpacity={0.096}
        fillRule="nonzero"
        d="M411.183 57.877c-1.722 2.087-3.097 3.523-4.126 4.306-.904.688-2.196 1.44-3.877 2.253l.327-3.347.376-.06c1.314-.22 2.415-.52 3.301-.9.986-.424 2.319-1.174 3.999-2.252Z"
      />
      <Path
        fill="#372630"
        d="M397.945 43.744a1.089 1.089 0 1 0 0-2.178 1.089 1.089 0 0 0 0 2.178ZM406.756 43.744a1.089 1.089 0 1 0 0-2.178 1.089 1.089 0 0 0 0 2.178Z"
      />
      <Path
        stroke="#A86F59"
        strokeLinecap="round"
        strokeWidth={0.653}
        d="M400.48 43.337c0 .992-2.06 4.183-1.7 4.887.238.47 1.005.651 2.3.544M395.227 39.237a8.536 8.536 0 0 1 3.99-.071M410.656 39.402c-2.049-.873-4.22-.995-6.514-.367"
      />
      <Path
        fill="#1E2239"
        fillRule="nonzero"
        d="M395.805 35.645c-4.114-6.343-4.892-10.602-2.334-12.776 3.838-3.263 22.363-3.606 25.164-1.72 2.8 1.885 4.003 5.16 4.003 5.16s6.39 4.645 4.8 10.508c-1.06 3.909-3.429 8.803-7.105 14.683 2.183-9.789 2.183-14.683 0-14.683-3.275 0-1.565 10.4-2.909 9.846-1.343-.554-3.046-11.832-6.014-12.582-1.978-.5-7.18.02-15.605 1.564Z"
      />
      <Path
        fill="#FFF"
        d="m406.664 77.485 12.728-6.869c11.535-.412 19.52.877 23.952 3.87.48.323 1.512 1.145 3.096 2.465l-3.818 29.006c-8.13 36.243-12.073 56.487-11.83 60.732.209 3.659-11.149 8.6-26.525 8.998-11.38.296-27.908-3.105-28.207-5.053-.702-4.582-5.74-5.628-6.797-11.152-.705-3.683 1.409-24.336 6.34-61.959l4.193-25.531c2.49-.844 4.11-1.303 4.861-1.376 3.412-.331 8.89-.331 16.434 0l5.573 6.87Z"
      />
      <Path
        fill="#FFB294"
        d="m373.592 358.76-2.791 15.322h-10.932V358.76zM438.44 355.793l1 19.086h-11.326l-3.98-19.086z"
      />
      <Path
        fill="#2A3036"
        d="M359.869 371h11.696l-.02 13.994a1 1 0 0 1-.995.998l-36.382.202a1 1 0 0 1-.995-1.149l.361-2.402a1 1 0 0 1 .6-.773L359.869 371ZM439.697 371H427l.02 13.993a1 1 0 0 0 .996.999l37.382.202a1 1 0 0 0 .994-1.149l-.36-2.402a1 1 0 0 0-.6-.773L439.696 371Z"
      />
      <Path
        fill="#73157C"
        fillRule="nonzero"
        d="M376.06 170.634 360.154 272.29l-2.404 96.973h15.842l14.437-93.688 11.89-54.52 8.12 57.235 16.001 90.973h17.285l-4.876-96.973-7.832-103.603c-5.205 2.586-12.149 4.311-20.832 5.175-8.683.865-19.258-.212-31.725-3.228Z"
      />
      <Path
        fill="#000"
        fillOpacity={0.27}
        fillRule="nonzero"
        d="m410.615 175.263 18.088-5.444 7.746 102.47 4.876 96.974h-2.319l-14.607-188.851-9.384 2.933-4.574-2.89zM399.513 222.915l-11.484 52.66-13.714 89.002 7.66-86.42z"
      />
      <Path
        fill="#000"
        fillOpacity={0.27}
        fillRule="nonzero"
        d="m428.703 169.82.683 9.034-14.37 4.49-4.575-2.888.174-5.193zM375.096 176.791l.964-6.157c3.564.862 6.973 1.566 10.228 2.111v10.6l-2.5-1.137c-2.447-1.12-4.282-1.99-5.506-2.61-1.004-.51-2.066-1.445-3.186-2.807l.964-6.157-.964 6.157Z"
      />
      <Path
        fill="#C4D1FF"
        fillRule="nonzero"
        d="M407.664 77.225 406.2 76.9l-.955 4.316-1.186 5.413c-9.162 42.095-13.028 66.242-11.612 72.805l.046.201c1.312 5.318 2.085 10.58 2.322 15.785l1.498-.068c-.24-5.305-1.029-10.663-2.363-16.076-1.571-6.37 3.006-33.875 13.714-82.05Z"
      />
      <Path
        fill="#3C62FF"
        fillRule="nonzero"
        d="m428.617 168.687.481 6.617c-12.321 3.904-23.277 5.335-32.867 4.293-8.713-.946-15.655-2.486-20.826-4.621l.655-4.342c12.467 3.016 23.042 4.093 31.725 3.228 8.42-.838 15.205-2.486 20.354-4.943l.478-.232ZM373.835 193.476l7.968-6.96a.5.5 0 0 0-.658-.754l-7.967 6.96a.5.5 0 0 0 .657.754ZM420.77 188.13l8.403 5.168a.5.5 0 1 0 .524-.851l-8.403-5.17a.5.5 0 0 0-.524.852Z"
      />
      <Path
        fill="#C4D1FF"
        fillRule="nonzero"
        d="m419.392 70.616-12.095 6.447 7.402 8.085 7.403-14.532zM401.091 70.616l5.841 6.447-8.156 7.26-.772-13.85z"
      />
      <Path
        fill="#73157C"
        fillRule="nonzero"
        d="M421.389 70.368c-7.774 37.35-11.661 62.075-11.661 74.172 0 12.098.237 24.07.713 35.916 6.922-.663 12.001-1.456 15.237-2.38 3.236-.924 7.814-3.289 13.734-7.095-2.505-13.794-3.158-24.837-1.958-33.13 1.2-8.293 3.773-28.441 7.72-60.446-1.977-3.242-5.67-5.171-11.076-5.789-5.406-.617-9.643-1.033-12.71-1.248Z"
      />
      <Path
        fill="#000"
        fillOpacity={0.27}
        fillRule="nonzero"
        d="m436.303 90.67 6.574 5.27-2.176 17.304c-1.502 11.823-2.584 20.025-3.247 24.607-1.025 7.08-.699 16.163.977 27.252h-5.702l-7.051-16.44 8.42-5.449 2.205-52.544Z"
      />
      <Path
        fill="#FFB294"
        d="M438.39 147.956c-8.295-.63-12.804-.388-13.528.724-.724 1.112.89 2.267 4.84 3.465l8.688-4.19Z"
      />
      <Path
        fill="#FFB294"
        d="M412.521 161.898c.637 1.467 7.583-1.25 8.024-.744.405.465-2.712 2.53-2.052 2.933 2.052 1.255 6.103-1.406 7.455-1 3.036.913 4.645 2.773 6.998 1.683 1.54-.714 5.221-4.106 11.042-10.177l-3.643-8.975c-.891.64-1.699 1.124-2.422 1.45-8.145 3.671-26.039 13.363-25.402 14.83Z"
      />
      <Path
        fill="#73157C"
        fillRule="nonzero"
        d="M441.655 73.954c1.81.87 2.663 1.593 3.106 1.892 5.985 4.039 37.652 35.166 38.746 42.52 1.095 7.354-35.47 36.34-38.333 37.983-.748.429-8.227-9.212-7.37-10.731.857-1.52 24.46-25.482 24.46-27.252 0-1.18-9.789-8.565-19.357-16.901-15.25-13.286-3.062-28.38-1.252-27.51Z"
      />
      <Path
        fill="#3C62FF"
        fillRule="nonzero"
        d="m421.389 70.368 1.242.093.893.073 4.56 14.614-7.364 6.09 4.958 6.562-15.774 41.71.054-.811c.938-13.447 4.748-36.224 11.43-68.331Z"
      />
      <Path
        fill="#73157C"
        fillRule="nonzero"
        d="M399.004 70.473c-7.554 32.875-11.957 53.81-13.209 62.803-1.251 8.993-2.058 24.433-2.421 46.321-9.887-3.87-15.811-6.874-17.773-9.009-1.962-2.136 2.77-35.001 14.195-98.596 1.28-.752 3.28-1.294 6-1.624 2.718-.33 7.121-.296 13.208.105Z"
      />
      <Path
        fill="#FFB294"
        d="M365.065 53.221c4.932-9.84 8.338-15.466 10.219-16.878 2.82-2.117 8.87-5.785 11.011-5.71 2.142.075 8.101 1.192 8.063 2.279-.038 1.087-4.152.76-7.07.49-2.918-.271-5.828 4.405-5.828 4.405s6.06 4.795 5.414 7.447c-.646 2.653-6.562 7.206-9.114 7.116-.762-.026-2.612-.603-3.321-.115-.584.401-1.406 1.904-2.465 4.509l-6.91-3.543Z"
      />
      <Path
        fill="#73157C"
        fillRule="nonzero"
        d="M375.94 57.515c-.161 1.086-7.355 9.955-18.786 24.572 15.688-7.532 24.616-11.403 26.786-11.614 1.215-.118 1.685 11.854-1.625 16.97-1.604 2.477-3.657 6.582-10.049 8.924-8.822 3.232-29.405 8.999-34.837 8.43-8.429-.88-9.546-11.807-7.07-16.49 2.475-4.685 32.142-37.123 34.59-38.35.231-.115 11.154 6.473 10.992 7.558Z"
      />
      <Path
        fill="#000"
        fillOpacity={0.27}
        fillRule="nonzero"
        d="m372.266 96.367-.701.253c-9.167 3.27-28.843 8.73-34.136 8.178l-.072-.01 43.39-14.915c-1.555 2.24-3.907 4.818-8.48 6.494ZM368.404 76.777l.348-.16c-.19.517-.398 1.025-.621 1.525-1.076 2.408-6.905 5.946-17.486 10.612l-1.056.463 7.7-7.196 2.773-1.325c3.07-1.463 5.85-2.77 8.342-3.92l.348-.159-.348.16Z"
      />
      <Path
        fill="#3C62FF"
        fillRule="nonzero"
        d="m399.004 70.473-1.752 7.66c-6.416 28.179-10.222 46.468-11.418 54.87l-.039.273c-.063.454-.125.924-.186 1.411l-1.265-39.461 6.589-6.412-5.05-6.822 10.827-11.657 1.51.088.784.05Z"
      />
      <G fillRule="nonzero">
        <Path
          fill="#FFBF29"
          d="M115.682 322H82.245a4.666 4.666 0 0 0-4.666 4.666v55.21a4.666 4.666 0 0 0 4.666 4.665h33.437V322Z"
        />
        <Path
          fill="#EA9C02"
          d="M169.336 322h-56.765a4.666 4.666 0 0 0-4.665 4.666v55.21a4.666 4.666 0 0 0 4.665 4.665h56.765a4.666 4.666 0 0 0 4.666-4.666v-55.21a4.666 4.666 0 0 0-4.666-4.665Z"
        />
        <Path
          fill="#FCA904"
          d="M172.042 322h-62.868a1.555 1.555 0 0 0-1.498 1.971l6.595 23.745a3.11 3.11 0 0 0 2.997 2.278h61.686a1.555 1.555 0 0 0 1.498-1.972l-6.912-24.883a1.555 1.555 0 0 0-1.498-1.139Z"
        />
        <Path
          fill="#FCA904"
          d="M79.539 322h27.098a1.555 1.555 0 0 1 1.499 1.971l-6.596 23.745a3.11 3.11 0 0 1-2.997 2.278H72.627a1.555 1.555 0 0 1-1.499-1.972l6.912-24.883c.187-.673.8-1.139 1.499-1.139ZM167.781 363.99h-20.218v5.443h20.218zM165.06 373.322h-22.551a2.722 2.722 0 1 0 0 5.443h22.55a2.722 2.722 0 1 0 0-5.443Z"
        />
      </G>
      <G transform="translate(239 55)">
        <Ellipse cx={39.144} cy={38.783} fill="#C4D1FF" fillRule="nonzero" rx={34.326} ry={35.089} />
        <Ellipse cx={34.326} cy={35.089} fill="#F5A623" fillRule="nonzero" rx={34.326} ry={35.089} />
        <Text
          fill="#182160"
          fontFamily={processFontFamily('Comfortaa_500Medium') || 'Comfortaa_500Medium'}
          fontSize={56.16}
          fontWeight={800}
          letterSpacing={0.562}
        >
          <TSpan x={22.566} y={56.354}>
            {'?'}
          </TSpan>
        </Text>
      </G>
      <G fillRule="nonzero">
        <Path
          fill="#EA9C02"
          d="M287.576 144h37.152a5.184 5.184 0 0 1 5.184 5.184v61.344a5.184 5.184 0 0 1-5.184 5.184h-37.152V144Z"
        />
        <Path
          fill="#FFBF29"
          d="M227.96 144h63.072a5.184 5.184 0 0 1 5.184 5.184v61.344a5.184 5.184 0 0 1-5.184 5.184H227.96a5.184 5.184 0 0 1-5.184-5.184v-61.344A5.184 5.184 0 0 1 227.96 144Z"
        />
        <Path
          fill="#FCA904"
          d="M224.953 144h69.854a1.728 1.728 0 0 1 1.665 2.19l-7.329 26.383a3.456 3.456 0 0 1-3.33 2.531h-68.54a1.728 1.728 0 0 1-1.665-2.19l7.68-27.648a1.728 1.728 0 0 1 1.665-1.266Z"
        />
        <Path
          fill="#FCA904"
          d="M327.735 144h-30.11a1.728 1.728 0 0 0-1.665 2.19l7.329 26.383a3.456 3.456 0 0 0 3.33 2.531h28.796a1.728 1.728 0 0 0 1.665-2.19l-7.68-27.648a1.728 1.728 0 0 0-1.665-1.266ZM229.688 190.656h22.464v6.048h-22.464zM232.712 201.024h25.056a3.024 3.024 0 1 1 0 6.048h-25.056a3.024 3.024 0 1 1 0-6.048Z"
        />
      </G>
      <G fillRule="nonzero">
        <Path fill="#373E6B" d="M186 219.5v166.92l8.435-.085V219.5z" />
        <Path fill="#1E2239" d="M220 219.5v166.92l8.435-.085V220z" />
        <Path
          fill="#3D4573"
          d="M409 224c16.569 0 30 13.431 30 30v102c0 16.569-13.431 30-30 30h-97c-16.569 0-30-13.431-30-30V254c0-16.569 13.431-30 30-30h97Zm-52 7c-12.029 0-21.803 9.654-21.997 21.636L335 253v104c0 12.15 9.85 22 22 22 12.029 0 21.803-9.654 21.997-21.636L379 357V253c0-12.15-9.85-22-22-22Z"
        />
        <Path
          fill="#48528B"
          d="m354.587 224-.003 7.021c-10.23.473-18.403 8.83-18.581 19.135l-.003.344v109c0 10.44 8.205 18.964 18.518 19.476l-.002 7.024H312c-16.569 0-30-13.431-30-30V254c0-16.569 13.431-30 30-30h42.587Z"
        />
        <Path
          fill="#1E2239"
          d="M357 224c16.569 0 30 13.431 30 30v102c0 16.569-13.431 30-30 30h-45c-16.569 0-30-13.431-30-30V254c0-16.569 13.431-30 30-30h45Zm-5 7h-35c-14.763 0-26.758 11.848-26.996 26.554L290 258v94c0 14.763 11.848 26.758 26.554 26.996L317 379h35c14.763 0 26.758-11.848 26.996-26.554L379 352v-94c0-14.763-11.848-26.758-26.554-26.996L352 231Z"
        />
        <Path fill="#383F6A" d="M176 215h263v9H176z" />
        <Path fill="#1E2239" d="M386 215h53v9h-53z" />
      </G>
    </G>
  </Svg>
)
export default NoInvoiceSVG
