import React, { ReactNode } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import Button from '../../Button'
import TextInputWithIcon from '../../TextInputWithIcon'
import useStyles from '../login-styles'
import { yupResolver } from '@hookform/resolvers/yup'
import SignupValidator from '../../../validators/SignupValidator'
import icons from '../../../constants/icons'
import ColoredText from '../../CustomText/ColoredText'

interface IProps {
  onSubmit: (params: { email: string; password: string }) => void
  loading: boolean
  submitButtonText?: ReactNode
  style?: StyleProp<ViewStyle>
}

const UpdatePasswordForm = ({ onSubmit, loading, submitButtonText, style }: IProps) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<{
    email: string
    password: string
    confirmPassword: string
  }>({
    resolver: yupResolver(SignupValidator),
  })
  const { t } = useTranslation()

  const styles = useStyles()
  return (
    <View style={style}>
      <Controller
        control={control}
        name={'email'}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputWithIcon
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            icon={[icons.faIconStyle, 'user']}
            style={styles.textInput}
            placeholder={`${t('placeholder.baseLogin.email')}`}
            editable={!loading}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
      />
      <View style={styles.errorWrapper}>{errors.email ? <ColoredText>{errors.email.message}</ColoredText> : null}</View>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputWithIcon
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            icon={[icons.faIconStyle, 'key']}
            secureTextEntry
            style={styles.textInput}
            placeholder={`${t('placeholder.baseLogin.password')}`}
            autoCompleteType={'password'}
            autoCorrect={false}
            editable={!loading}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
        name={'password'}
      />
      <View style={styles.errorWrapper}>
        {errors.password ? <ColoredText>{errors.password.message}</ColoredText> : null}
      </View>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputWithIcon
            icon={[icons.faIconStyle, 'key']}
            secureTextEntry
            style={styles.textInput}
            placeholder={`${t('placeholder.baseLogin.confirmPassword')}`}
            autoCompleteType={'password'}
            autoCorrect={false}
            editable={!loading}
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
        name={'confirmPassword'}
      />
      <View style={styles.errorWrapper}>
        {errors.confirmPassword ? <ColoredText>{errors.confirmPassword.message}</ColoredText> : null}
      </View>
      <Button style={styles.button} textStyle={styles.buttonText} onPress={handleSubmit(onSubmit)} disabled={loading}>
        {submitButtonText || t('button.baseResetPassword.reset')}
      </Button>
    </View>
  )
}

export default UpdatePasswordForm
