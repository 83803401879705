import { StyleSheet } from 'react-native'
import colors from '../../../../../../../constants/colors'
import { useHighlightColor } from '../../../../../../../hooks/useColors'
import { Themes } from '../../../../../../../types/dashbar/theme'
import { useTheme } from '../../../../../../../utils/types/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    extensionCard: {
      backgroundColor: theme === Themes.Dark ? '#1e2239' : '#FFF',
      borderRadius: 15,
      minHeight: 85,
      minWidth: 400,
      marginVertical: 5,
      flexDirection: 'row',
    },
    imageWrapper: {
      width: '14%',
      minWidth: 55,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    headerWrapper: {
      padding: 10,
      marginVertical: 5,
      minHeight: 70,
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      flexDirection: 'row',
    },
    footerWrapper: {
      padding: 10,
      minHeight: 70,
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      flexDirection: 'row',
      alignContent: 'center',
      borderTopColor: colors.color3,
      borderTopWidth: 1,
      backgroundColor: theme === Themes.Dark ? colors.color5 : '#000',
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,
    },
    descriptionWrapper: {
      width: '50%',
      minWidth: '50%',
      maxWidth: '50%',
      flexGrow: 1,
      minHeight: 40,
      flex: 1,
      flexWrap: 'wrap',
      marginHorizontal: 20,
      justifyContent: 'space-around',
      flexDirection: 'column',
    },
    actionWrapper: {
      width: '35%',
      minWidth: '35%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    titleText: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    descriptionText: {
      fontSize: 15,
    },
    button: {
      width: '60%',
      marginVertical: 5,
      paddingVertical: 5,
      paddingHorizontal: 0,
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 30,
      height: 30,
      borderRadius: 10,
      backgroundColor: useHighlightColor(),
    },
    disabled: {
      opacity: 0.6,
    },
  })
}

export default useStyles
