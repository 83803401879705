import React, { useState } from 'react'
import { Dimensions, Linking, Platform, SafeAreaView, TouchableOpacity, View } from 'react-native'
import useStyles from './styles'
import BugSVG from '../../../assets/imgs/settings/BugSVG'
import ColoredText from '../../CustomText/ColoredText'
import { useTranslation } from '../../../hooks/useTranslation'
import PageWrapper from '../../PageWrapper'
import md5 from 'md5'
import moment from 'moment'
import getApp from '../../../firebase'

/** @description
 * Migrated from @webstollen/shared-react-components
 */

type Props = { error: Error; resetError: () => void }

const FallbackComponent = (props: Props) => {
  const styles = useStyles()
  const windowWidth = Dimensions.get('window').width
  const { t } = useTranslation()
  const [error_code, setErrorCode] = useState<string | null>(null)

  const openTicket = () => {
    const id = md5(moment().format('x'))
    getApp()
      .firestore()
      .collection(`support_errors`)
      .doc(id)
      .set({ created: new Date(), error_msg: props.error.toString(), error_stack: props.error.stack, id: id })
      .then(() => {
        setErrorCode(id)
      })
  }
  const goHome = () => {
    if (Platform.OS === 'web') {
      location.replace(`https://app.dash.bar`)
    } else {
      props.resetError()
    }

    // Linking.openURL(`https://ws-url.de/help`)
  }

  const goToHelp = () => {
    Linking.openURL(`https://ws-url.de/help`)
  }

  return (
    <PageWrapper isScrollable>
      <SafeAreaView style={styles.container}>
        <View style={styles.content}>
          {windowWidth < 450 ? (
            <BugSVG width={300} height={150} style={{ marginTop: 5, marginBottom: 10 }} viewBox="0 0 520 400" />
          ) : (
            <BugSVG width={400} height={350} style={{ marginTop: 10, marginBottom: 15 }} viewBox="0 0 520 400" />
          )}
          <ColoredText style={styles.title}>{'Oops!'}</ColoredText>
          <ColoredText style={styles.subtitle}>{t('error_boundary.title')}</ColoredText>
          <ColoredText style={styles.error}>{t('error_boundary.message')}</ColoredText>
          <TouchableOpacity style={styles.button} onPress={goHome}>
            <ColoredText style={styles.buttonText}>{t('error_boundary.go_home')}</ColoredText>
          </TouchableOpacity>
        </View>
        {error_code && error_code !== null ? (
          <>
            <View style={{ width: '100%', flexDirection: 'row', marginVertical: 15 }}>
              <ColoredText style={styles.error_code_msg}>{t('error_boundary.reference')}</ColoredText>
              <ColoredText style={styles.error_code}>{error_code}</ColoredText>
            </View>
            <TouchableOpacity onPress={goToHelp}>
              <ColoredText style={styles.error}>{t('error_boundary.go_to_help')}</ColoredText>
            </TouchableOpacity>
          </>
        ) : (
          <TouchableOpacity onPress={openTicket}>
            <ColoredText style={styles.error}>{t('error_boundary.open_ticket')}</ColoredText>
          </TouchableOpacity>
        )}
      </SafeAreaView>
    </PageWrapper>
  )
}

export default FallbackComponent
