import React, { useContext, useEffect, useState } from 'react'
import { Platform, Image, View, TouchableOpacity } from 'react-native'
import Button from '../../../../../components/Button'
import MixedChartsRelevanz from '../../../../../components/VictoryCharts/MixedRelevanz'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import { IWidgetProps } from '../../../../../types/props/Widget'
import { useUpdateWidgetContext } from '../../../../../types/Widget'
import Widget from '../../../../components/WidgetNoAPI'
import RelevanzHistorySkeleton from './RelevanzHistorySkeleton'
import useStyles from './styles'
import { functions } from '../../../../../firebase'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { useFirestore } from 'react-redux-firebase'
import { SessionContext } from '../../../../../context/SessionContext'

const RelevanzHistory = ({ widget, dashboardId, shops }: IWidgetProps) => {
  const [stats, setStats] = useState<Array<Record<string, any>> | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<{ text: string; link: string | null } | null>(null)
  const [selectedShop, setSelectedShop] = useState<number | null>(widget?.settings?.relevanzShop || null)
  const { t } = useTranslation()
  const firestore = useFirestore()
  const { company } = useContext(SessionContext)
  const getRelevanzData = functions().httpsCallable('widgets-relevanz')
  const updateWidget = useUpdateWidgetContext()
  const [needsConfig, setNeedsConfig] = useState<boolean>(false)

  const selectShop = (shopId: number) => {
    setSelectedShop(shopId)
    const newWidget = {
      ...widget,
      settings: {
        ...widget.settings,
        relevanzShop: shopId,
      },
    }
    updateWidget(newWidget)
  }

  useEffect(() => {
    firestore
      .collection(`company/${company?.id}/extension_config`)
      .doc('relevanz')
      .get()
      .then((doc) => {
        if (!doc.exists || !doc.data()) {
          setNeedsConfig(true)
          return
        }
        if (!doc.data()?.apikey) {
          setNeedsConfig(true)
          return
        }
        setNeedsConfig(false)
      })
  }, [firestore, company])

  //Fetching Relevanz Data
  useEffect(() => {
    getRelevanzData({ apikey: widget.settings.apikey, type: 'details' })
      .then((data) => {
        setStats(data.data)
      })
      .catch(() => {
        setError({ text: `${t('widget_errors.relevanz.default')}`, link: null })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  let counter = 0

  return (
    <Widget
      title={'Relevanz Historie'}
      subtitle={
        selectedShop !== null && stats
          ? stats.find((v) => {
              return v.shopId === selectedShop
            })?.shopUrl
          : undefined
      }
      widget={widget}
      dashboardId={dashboardId}
      loading={loading}
      loadingSkeleton={<RelevanzHistorySkeleton />}
      isError={error !== null}
      error={error}
      needsConfig={needsConfig}
      shops={shops}
      image={{
        source:
          Platform.OS === 'web'
            ? require('../../../../../assets/imgs/widgets/relevanz/relevanz.png')
            : Image.resolveAssetSource(require('../../../../../assets/imgs/widgets/relevanz/relevanz.png')).uri,
        alt: '',
      }}
    >
      {([size]) => {
        return (
          <View>
            {error && error !== null ? (
              <ColoredText>{`${error.text}`}</ColoredText>
            ) : stats !== null && selectedShop === null ? (
              <ShopSelection shops={stats} selectShop={selectShop} />
            ) : (
              stats !== null &&
              stats
                .filter((v) => {
                  if (v?.shopId === selectedShop && counter === 0) {
                    counter++
                    return true
                  }
                  return false
                })
                .map((stat, index) => {
                  return <RelevanzHistoryView key={`${stat.shopId}_${index}`} size={size} data={stat} />
                })
            )}
          </View>
        )
      }}
    </Widget>
  )
}

const ShopSelection = ({
  shops,
  selectShop,
}: {
  shops: Array<Record<string, any>>
  selectShop: (shopId: number) => void
}) => {
  const [page, setPage] = useState<number>(0)
  const ROWS_PER_PAGE = 4
  const styles = useStyles()

  const decreasePage = () => {
    if (page > 0) {
      setPage(page - 1)
    } else {
      setPage(0)
    }
  }

  const increasePage = () => {
    if (shops.length > (page + 1) * ROWS_PER_PAGE) {
      setPage(page + 1)
    }
  }

  return (
    <View style={styles.shopSelectionWrapper}>
      <View style={styles.shopSelectionTitle}>
        <ColoredText>{`Wähle einen Shop aus`}</ColoredText>
      </View>
      {shops
        .filter((_v, index) => {
          //Currently not supporting more then 4 Shops for Design Reasons
          // console.log(
          //   `Page: ${page}, Index: ${index}, ${v.shopUrl}, ${
          //     index >= page * ROWS_PER_PAGE && index < (page + 1) * ROWS_PER_PAGE
          //   }`
          // )
          return index >= page * ROWS_PER_PAGE && index < (page + 1) * ROWS_PER_PAGE
        })
        .map((v, index) => {
          return (
            <TouchableOpacity
              key={`${v.shopId}_${index}_${v.shopUrl}`}
              style={styles.shopSelectionRow}
              onPress={() => {
                selectShop(v.shopId)
              }}
            >
              <ColoredText>{v.shopUrl ? v.shopUrl : v.shopId}</ColoredText>
            </TouchableOpacity>
          )
        })}
      <View style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center', alignContent: 'center' }}>
        {page > 0 && (
          <Button style={{ marginHorizontal: 5 }} onPress={decreasePage}>
            Zurück
          </Button>
        )}
        {shops.length > (page + 1) * ROWS_PER_PAGE && (
          <Button style={{ marginHorizontal: 5 }} onPress={increasePage}>
            Weiter
          </Button>
        )}
      </View>
    </View>
  )
}
const RelevanzHistoryView = ({
  size,
  data,
}: {
  size: { height: number; width: number }
  data: Record<string, any>
}) => {
  const shopData: Array<Record<string, any>> = data?.data || []
  const transformedData = shopData
    .sort((a, b) => {
      if (a.date > b.date) {
        return 1
      } else if (a.date < b.date) {
        return -1
      } else {
        return 0
      }
    })
    .map((v) => {
      return { line1: v.impressions, line2: v.expenses, bar1: v.turnover }
    })
  return (
    <MixedChartsRelevanz
      title={{ titleBar1: 'Umsatz', titleLine1: 'Einblendungen', titleLine2: 'Kosten' }}
      list={transformedData}
      size={size}
    />
  )
}
export default RelevanzHistory
