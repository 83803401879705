import React from 'react'
import { Platform, ScrollView, View } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Button from '../../Button'
import useStyles from '../login-styles'
import TextInputWithIcon from '../../TextInputWithIcon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { yupResolver } from '@hookform/resolvers/yup'
import CompanyValidator from '../../../validators/CompanyValidator'
import getDotProp from '../../../utils/getDotProp'
import icons from '../../../constants/icons'
import ColoredText from '../../CustomText/ColoredText'
import { ICompany } from '../../../types/company'

interface IProps {
  loading: boolean
  company: Partial<ICompany> | undefined | null
  onSubmit: (company: Partial<ICompany>) => void
  setChangeShopDataVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const CompanyForm = ({ onSubmit, loading, company, setChangeShopDataVisible }: IProps) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<Partial<ICompany>>({
    resolver: yupResolver(CompanyValidator),
    defaultValues: {
      name: company?.name ? company.name : '',
      ustId: company?.ustId ? company.ustId : '',
      supportEmail: company?.supportEmail ? company.supportEmail : '',
      billingAddress: { ...company?.billingAddress },
    },
  })

  const fields = [
    {
      label: t('label.userProfile.company'),
      name: 'name',
      icon: [icons.faIconStyle, 'industry'] as IconProp,
      placeholder: t('placeholder.userProfile.company'),
    },
    {
      label: t('label.userProfile.email'),
      name: 'billingAddress.email',
      icon: [icons.faIconStyle, 'envelope'] as IconProp,
      placeholder: t('placeholder.userProfile.email'),
    },
    {
      label: t('label.userProfile.firstName'),
      name: 'billingAddress.firstName',
      icon: [icons.faIconStyle, 'user'] as IconProp,
      placeholder: t('placeholder.userProfile.firstName'),
    },
    {
      label: t('label.userProfile.lastName'),
      name: 'billingAddress.lastName',
      icon: [icons.faIconStyle, 'user-tie'] as IconProp,
      placeholder: t('placeholder.userProfile.lastName'),
    },
    {
      label: t('label.userProfile.address1'),
      name: 'billingAddress.address1',
      icon: [icons.faIconStyle, 'map-marker-alt'] as IconProp,
      placeholder: t('placeholder.userProfile.address1'),
    },
    {
      label: t('label.userProfile.address2'),
      name: 'billingAddress.address2',
      icon: [icons.faIconStyle, 'map-marker'] as IconProp,
      placeholder: t('placeholder.userProfile.address2'),
    },
    {
      label: t('label.userProfile.zipCode'),
      name: 'billingAddress.zipCode',
      icon: [icons.faIconStyle, 'city'] as IconProp,
      placeholder: t('placeholder.userProfile.zipCode'),
    },
    {
      label: t('label.userProfile.city'),
      name: 'billingAddress.city',
      icon: [icons.faIconStyle, 'city'] as IconProp,
      placeholder: t('placeholder.userProfile.city'),
    },
    {
      label: t('label.userProfile.country'),
      name: 'billingAddress.country',
      icon: [icons.faIconStyle, 'city'] as IconProp,
      placeholder: t('placeholder.userProfile.country'),
    },
    {
      label: t('label.userProfile.ustId'),
      name: 'ustId',
      icon: [icons.faIconStyle, 'funnel-dollar'] as IconProp,
      placeholder: t('placeholder.userProfile.ustId'),
    },
    {
      label: t('label.userProfile.supportEmail'),
      name: 'supportEmail',
      icon: [icons.faIconStyle, 'envelope'] as IconProp,
      placeholder: t('placeholder.userProfile.supportEmail'),
    },
  ]
  const FormInput = ({
    field,
  }: {
    field: {
      label: string
      name: string
      icon: IconProp
      placeholder: string
    }
  }) => {
    return (
      <View key={field.name} style={{ flexDirection: 'row' }}>
        <View style={{ flexDirection: 'column', width: '100%' }}>
          <ColoredText>{field.label}</ColoredText>
          <Controller
            name={field.name}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInputWithIcon
                key={field.label}
                style={styles.textInput}
                onChangeText={onChange}
                onBlur={onBlur}
                value={`${value as string}`}
                icon={field.icon}
                placeholder={field.placeholder}
                editable={!loading}
              />
            )}
          />

          <View style={styles.errorWrapper}>
            {getDotProp(errors, field.name) ? (
              <ColoredText style={{ color: 'red' }}>{getDotProp(errors, field.name).message}</ColoredText>
            ) : null}
          </View>
        </View>
      </View>
    )
  }
  const WebForm = () => {
    const listLeft = [
      {
        label: t('label.userProfile.company'),
        name: 'name',
        icon: [icons.faIconStyle, 'industry'] as IconProp,
        placeholder: t('placeholder.userProfile.company'),
      },
      {
        label: t('label.userProfile.email'),
        name: 'billingAddress.email',
        icon: [icons.faIconStyle, 'envelope'] as IconProp,
        placeholder: t('placeholder.userProfile.email'),
      },
      {
        label: t('label.userProfile.firstName'),
        name: 'billingAddress.firstName',
        icon: [icons.faIconStyle, 'user'] as IconProp,
        placeholder: t('placeholder.userProfile.firstName'),
      },
      {
        label: t('label.userProfile.lastName'),
        name: 'billingAddress.lastName',
        icon: [icons.faIconStyle, 'user-tie'] as IconProp,
        placeholder: t('placeholder.userProfile.lastName'),
      },
      {
        label: t('label.userProfile.ustId'),
        name: 'ustId',
        icon: [icons.faIconStyle, 'funnel-dollar'] as IconProp,
        placeholder: t('placeholder.userProfile.ustId'),
      },
      {
        label: t('label.userProfile.supportEmail'),
        name: 'supportEmail',
        icon: [icons.faIconStyle, 'envelope'] as IconProp,
        placeholder: t('placeholder.userProfile.supportEmail'),
      },
    ]

    const listRight = [
      {
        label: t('label.userProfile.address1'),
        name: 'billingAddress.address1',
        icon: [icons.faIconStyle, 'map-marker-alt'] as IconProp,
        placeholder: t('placeholder.userProfile.address1'),
      },
      {
        label: t('label.userProfile.address2'),
        name: 'billingAddress.address2',
        icon: [icons.faIconStyle, 'map-marker'] as IconProp,
        placeholder: t('placeholder.userProfile.address2'),
      },
      {
        label: t('label.userProfile.zipCode'),
        name: 'billingAddress.zipCode',
        icon: [icons.faIconStyle, 'city'] as IconProp,
        placeholder: t('placeholder.userProfile.zipCode'),
      },
      {
        label: t('label.userProfile.city'),
        name: 'billingAddress.city',
        icon: [icons.faIconStyle, 'city'] as IconProp,
        placeholder: t('placeholder.userProfile.city'),
      },
      {
        label: t('label.userProfile.country'),
        name: 'billingAddress.country',
        icon: [icons.faIconStyle, 'city'] as IconProp,
        placeholder: t('placeholder.userProfile.country'),
      },
    ]
    return (
      <ScrollView
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <View
          style={{
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <View style={{ width: '50%', paddingHorizontal: 5, minWidth: 200 }}>
            {listLeft.map((item) => (
              <FormInput key={item.name} field={item} />
            ))}
          </View>
          <View style={{ width: '50%', paddingHorizontal: 5, minWidth: 200 }}>
            {listRight.map((item) => (
              <FormInput key={item.name} field={item} />
            ))}
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <Button
            style={styles.cancelButton}
            onPress={() => {
              setChangeShopDataVisible(false)
              // if (modalRefData && modalRefData.current) modalRefData.current.setVisible(false)
            }}
          >
            <>{t('accountOverview.profile.cancel')}</>
          </Button>
          <Button
            style={[loading || !isDirty ? styles.buttonDisabled : styles.button, { width: '45%' }]}
            textStyle={styles.buttonText}
            onPress={handleSubmit(onSubmit, console.debug)}
            disabled={loading || !isDirty}
          >
            {t('btn.save')}
          </Button>
        </View>
      </ScrollView>
    )
  }

  if (Platform.OS === 'web') return <WebForm />
  return (
    <View>
      <View style={{ flexDirection: 'column' }}>
        {fields.map((field) => (
          <View key={field.name} style={{ flexDirection: 'row' }}>
            <View style={{ flexDirection: 'column', width: '100%' }}>
              <ColoredText>{field.label}</ColoredText>
              <Controller
                name={field.name}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInputWithIcon
                    style={styles.textInput}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={`${value as string}`}
                    icon={field.icon}
                    placeholder={field.placeholder}
                    editable={!loading}
                  />
                )}
              />

              <View style={styles.errorWrapper}>
                {getDotProp(errors, field.name) ? (
                  <ColoredText style={{ color: 'red' }}>{getDotProp(errors, field.name).message}</ColoredText>
                ) : null}
              </View>
            </View>
          </View>
        ))}
      </View>
      <Button
        style={loading || !isDirty ? styles.buttonDisabled : styles.button}
        textStyle={styles.buttonText}
        onPress={handleSubmit(onSubmit, console.debug)}
        disabled={loading || !isDirty}
      >
        {t('btn.save')}
      </Button>
    </View>
  )
}

export default CompanyForm
