import { StyleSheet, useWindowDimensions } from 'react-native'
import { useSecondaryBackground } from '../../hooks/useColors'

const useStyles = () => {
  const windowWidth = useWindowDimensions().width
  return StyleSheet.create({
    wrapper: {
      height: 52,
      maxHeight: 52,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: useSecondaryBackground(),
    },
    container: {
      width: '100%',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      alignItems: 'center',
      backgroundColor: useSecondaryBackground(),
    },
    backButton: {
      width: '10%',
    },
    additionalControls: {
      width: 30,
      alignItems: 'flex-end',
    },
    spacer: {
      flex: 2,
    },
    links: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: useSecondaryBackground(),
    },
    titleWrapper: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 5,
    },
    title: {
      fontSize: windowWidth <= 500 ? 16 : windowWidth <= 750 ? 18 : 20,
      maxWidth: '60%',
      alignContent: 'center',
      justifyContent: 'center',
    },
  })
}

export default useStyles
