import React, { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../../../../../context/SessionContext'
import {
  ActivityIndicator,
  FlatList,
  Platform,
  RefreshControl,
  SafeAreaView,
  Share,
  TouchableOpacity,
  View,
} from 'react-native'
import { useFirestore } from 'react-redux-firebase'
import useStyles from './styles.native'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import { useTranslation } from '../../../../../hooks/useTranslation'
import moment from 'moment'
import numbro from 'numbro'
import { functions } from '../../../../../firebase'
import * as FileSystem from 'expo-file-system'
import { Toast } from 'native-base'
import colors from '../../../../../constants/colors'
import { useTextColor } from '../../../../../hooks/useColors'
import NoInvoiceSVG from '../../../../../assets/imgs/settings/NoInvoiceSVG'

const Invoices = () => {
  const { company } = useContext(SessionContext)
  const [reload, setReload] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [invoices, setInvoices] = useState<Array<Record<string, any>> | null | undefined>(undefined)
  const styles = useStyles()
  const textColor = useTextColor()
  const { t } = useTranslation()
  const firestore = useFirestore()
  useEffect(() => {
    if (reload === true) setReload(false)
    firestore
      .collection(`company/${company?.id}/bills`)
      .get()
      .then((docs) => {
        if (docs.empty) {
          setInvoices(null)
        } else {
          const invoicesArray: Array<Record<string, any>> = []
          docs.forEach((doc) => {
            invoicesArray.push({ ...doc.data(), id: doc.id })
          })
          setInvoices(
            invoicesArray.sort((v1, v2) => {
              if (v1.created.toDate() > v2.created.toDate()) {
                return -1
              } else if (v1.created.toDate() < v2.created.toDate()) {
                return 1
              } else {
                return 0
              }
            })
          )
        }
      })
      .catch(() => {
        setInvoices(null)
      })
  }, [company, reload])

  const loadPDF = async (id: string, name: string) => {
    setLoading(true)
    if (Platform.OS === 'ios') {
      try {
        const getPDF = functions().httpsCallable('calls-sevdesk-getInvoiceAsPDF')
        const pdf = await (await getPDF({ invoiceId: id })).data
        const fileUri = `${FileSystem.documentDirectory}${encodeURI(name)}.pdf`
        await FileSystem.writeAsStringAsync(fileUri, pdf as string, { encoding: FileSystem.EncodingType.Base64 })
        await Share.share({ url: fileUri })
      } catch {
        setLoading(false)
      }
    } else {
      Toast.show({
        title: t('accountOverview.bills.cant_be_open.title'),
        description: t('accountOverview.bills.cant_be_open.text'),
      })
    }
    setLoading(false)
  }

  const Item = ({ invoice }: { invoice: Record<string, any> }) => (
    <View style={styles.invoiceCard}>
      <View>
        <ColoredText style={styles.invoiceNumberText}>{invoice.invoiceNumber}</ColoredText>
        <ColoredText style={styles.invoiceDateText}>{`${moment(invoice.created.toDate()).format(
          'DD.MM.YYYY'
        )}`}</ColoredText>
        <View style={styles.invoiceStateWrapper}>
          <ColoredText style={styles.invoiceStateText}>{`${t('settings.company.invoices.paid')}`}</ColoredText>
        </View>
      </View>
      <View style={styles.actionWrapper}>
        <ColoredText style={styles.invoiceAmountText}>
          {`${numbro(
            numbro.unformat(
              numbro.languageData().delimiters.thousands === ','
                ? invoice.sumGross.replace('', '')
                : invoice.sumGross.replace('.', ',')
            )
          ).formatCurrency({
            ...numbro.languageData().currencyFormat,
            mantissa: 2,
            currencySymbol: ' ',
            spaceSeparatedCurrency: true,
          })} €`}
        </ColoredText>
        <View>
          {loading ? (
            <ActivityIndicator size={'small'} color={textColor} />
          ) : (
            <TouchableOpacity onPress={async () => loadPDF(invoice?.invoiceId, invoice?.invoiceNumber)}>
              <ColoredText style={{ color: colors.error }}>PDF</ColoredText>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  )

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {(invoices && invoices.length <= 0) || invoices === null ? (
        <View style={{ width: '90%' }}>
          <NoInvoiceSVG width={'100%'} height={300} style={{ marginTop: 50, marginBottom: 30 }} viewBox="0 0 500 400" />
          <ColoredText style={{ textAlign: 'center', fontSize: 24 }}>{`${t(
            'settings.mobile.invoices.no_invoices'
          )}`}</ColoredText>
        </View>
      ) : (
        <FlatList
          refreshControl={
            <RefreshControl
              refreshing={reload}
              onRefresh={() => {
                setReload(true)
              }}
            />
          }
          nestedScrollEnabled
          data={invoices}
          style={{ height: '100%' }}
          renderItem={({ item }) => <Item invoice={item} />}
          keyExtractor={(item) => item.id}
        />
      )}
    </SafeAreaView>
  )
}

export default Invoices
