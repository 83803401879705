import getApp from '../../firebase'

export const reportValid = (report: Record<string, any>): boolean => {
  if (!report?.data) return false
  if (!report?.data?.currency) return false
  if (
    !report?.data?.largestCustomerGroup ||
    !report.data.largestCustomerGroup?.value ||
    !report.data.largestCustomerGroup?.name
  )
    return false
  if (
    !report?.data?.popularPaymentMethod ||
    !report.data.popularPaymentMethod?.value ||
    !report.data.popularPaymentMethod?.name
  )
    return false
  if (!report?.data?.runner || report?.data.runner.length < 5) return false
  if (
    !report?.data?.sales ||
    !report?.data?.sales?.quarter_1 ||
    !report?.data?.sales?.quarter_2 ||
    !report?.data?.sales?.quarter_3 ||
    !report?.data?.sales?.quarter_4 ||
    !report?.data?.sales?.total
  )
    return false
  if (!report?.data?.visitors?.total) return false
  return true
}

export const reportViewedByUser = async (year: number, uid: string): Promise<boolean> => {
  const userDoc = await getApp().firestore().collection(`user`).doc(uid).get()
  if (userDoc.exists) {
    const data = userDoc.data()
    if (data.annual_reports && data?.annual_reports?.contains(year)) return true
    return false
  } else {
    return true
  }
}
