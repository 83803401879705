import React, { ReactNode, useContext, useEffect } from 'react'
import { StatusBar, StatusBarStyle } from 'expo-status-bar'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Helmet } from 'react-helmet'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import '../../locales/i18n'
import { LanguageContext, useSetLanguage } from '../../common/helpers/language'
import colors from '../../constants/colors'
import { NativeBaseProvider, StorageManager } from 'native-base'
import { customNativeBaseTheme } from './NativeBase/customNativeBaseTheme'
import numbro from 'numbro'
import moment from 'moment'
import { Platform } from 'react-native'
import { useSelector } from 'react-redux'
import { Interfaces } from '../../store'
import { SessionContext } from '../../context/SessionContext'
import { useSetTheme } from '../../module/base/store/actions'
import { Languages } from '../../types/language'
import { Themes } from '../../types/dashbar/theme'
import { isThemeDark, ThemeContext } from '../../utils/types/theme'
import { getSecondaryBackground } from '../../hooks/useColors'

const lightTheme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: colors.lightBg1,
      paper: colors.lightBg2,
    },
  },
})

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: colors.darkBg1,
      paper: colors.darkBg2,
    },
  },
})

const ThemeLanguageWrapper = ({ children }: { children?: ReactNode }) => {
  // const { theme, language } = useSelector((state: Interfaces.Store) => state.base.config)
  const language = useSelector((state: Interfaces.Store) => state.language.language)
  const theme = useSelector((state: Interfaces.Store) => state.theme.theme)
  const { user } = useContext(SessionContext)
  const setTheme = useSetTheme()
  const setLanguageHook = useSetLanguage()

  useEffect(() => {
    if (user && user.preferences?.theme) {
      if ((user.preferences.theme as Themes) !== theme) {
        setTheme(user.preferences.theme as Themes)
      }
    }
  }, [user?.preferences, theme])

  useEffect(() => {
    if (user && user.preferences?.language) {
      const lang = user.preferences?.language === 'de' ? Languages.DE : Languages.EN
      if (lang !== language) {
        setLanguageHook(lang)
        numbro.setLanguage(lang)
        moment.locale(lang)
      }
    }
  }, [user?.preferences, language])

  //ToDo Replaced line above by two bottom lines. Check if this makes problems
  //const theme = useLoadTheme()
  //const language = useLoadLanguage()
  const themeColorMode = isThemeDark(theme) ? 'dark' : 'light'
  const statusBarColorMode = isThemeDark(theme)
    ? Platform.select<StatusBarStyle>({ default: 'light', web: 'dark' })
    : Platform.select<StatusBarStyle>({ default: 'dark', web: 'light' })

  const nativeBaseColorModeManager: StorageManager = {
    get: async () => {
      try {
        const val = themeColorMode
        return val === 'dark' ? 'dark' : 'light'
      } catch {
        return 'light'
      }
    },
    set: () => {
      // try {
      //   // Set value here if necessary
      // } catch (e) {
      //   console.log(e)
      // }
    },
  }

  // numbro.setLanguage(language)
  // moment.locale(language)

  return (
    <MuiThemeProvider theme={theme === Themes.Dark ? darkTheme : lightTheme}>
      <ThemeContext.Provider value={theme}>
        <LanguageContext.Provider value={language}>
          <NativeBaseProvider theme={customNativeBaseTheme} colorModeManager={nativeBaseColorModeManager}>
            <Helmet defaultTitle={'dash.bar'} titleTemplate={'%s | dash.bar'}></Helmet>
            <StatusBar style={statusBarColorMode} />
            <SafeAreaView style={{ flex: 1, backgroundColor: getSecondaryBackground(theme) }}>{children}</SafeAreaView>
          </NativeBaseProvider>
        </LanguageContext.Provider>
      </ThemeContext.Provider>
    </MuiThemeProvider>
  )
}

export default ThemeLanguageWrapper
