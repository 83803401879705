import React, { useContext, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import useStyles from './styles'
import useDashboards from '../../../../hooks/dashboard/useDashboards'
import useAddDashboard from '../../../../hooks/dashboard/useAddDashboard'
import useAddWidget from '../../../../hooks/widget/useAddWidget'
import { useTranslation } from 'react-i18next'
import { SessionContext } from '../../../../context/SessionContext'
import { UpsellingContext } from '../../../../context/UpsellingContext'
import CreateDashboardModal, { CreateDashboardFormType } from './CreateDashboardModal'
import { SubmitHandler } from 'react-hook-form'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import NewUserTutorial from '../../../dashboard/components/NewCostumerTutorial/NewUserTutorial'
import icons from '../../../../constants/icons'
import PageWrapper from '../../../../components/PageWrapper'
import colors from '../../../../constants/colors'
import { useNavigate } from '../../../../components/Router/Router'
import LoadingScreen from '../../../../components/LoadingScreen'
import { FontAwesomeIcon } from '../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../../../../components/CustomText/ColoredText'

export const defaultWidgetsDefinitions: Record<string, Array<string>> = {
  empty: [],
  standard: [
    'shop.sales_v1',
    'shop.conversion_v1',
    'shop.live_tracker_v1',
    'misc.advertisement_v1',
    'shop.orders_v1',
    'shop.runners_v1',
    'shop.order_value_v1',
    'shop.status_v1',
  ],
}

const ManageDashboards = () => {
  const styles = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { shops, company, user } = useContext(SessionContext)

  const dashboards = useDashboards()
  const addDashboard = useAddDashboard()
  const addWidget = useAddWidget()

  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const { open } = useContext(UpsellingContext)
  const numberOfDashboards = dashboards !== null ? Object.keys(dashboards).length : 0
  const canCreateDashboard =
    numberOfDashboards <
    (company?.authorizations['dashboard.dashboards'] ? +company?.authorizations['dashboard.dashboards'] : 0)
  const manageDashboards = company?.authorizations['dashboard.edit']
  const submitDashboard: SubmitHandler<CreateDashboardFormType> = async ({
    dashboardName,
    defaultWidgets,
    shopDomain,
  }: CreateDashboardFormType) => {
    setLoading(true)

    const dashboardId = await addDashboard({
      title: dashboardName,
    })

    await Promise.all(
      defaultWidgetsDefinitions[defaultWidgets].map(async (type) =>
        addWidget(
          {
            type,
            settings: {
              shop: shopDomain,
              interval: 'D30',
            },
          },
          dashboardId
        )
      )
    )
      .then(() => {
        setVisible(false)
        setLoading(false)
        navigate(`/settings/dashboard/${dashboardId}`)
      })
      .finally(() => {
        setVisible(false)
        setLoading(false)
      })
  }

  if (!isLoaded(dashboards)) {
    return <LoadingScreen />
  }
  if (isEmpty(shops)) {
    return <NewUserTutorial />
  }
  return (
    <PageWrapper isScrollable={true}>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          {Object.entries(dashboards).map(([key, { icon, title }]) => (
            <TouchableOpacity
              key={key}
              onPress={() => {
                if (manageDashboards) {
                  navigate(`/settings/dashboard/${key}`)
                } else {
                  open()
                }
              }}
              style={key === user?.mainDashboard ? styles.buttonMain : styles.button}
            >
              <FontAwesomeIcon icon={[icons.faIconStyle, icon]} />
              <ColoredText style={styles.buttonText} numberOfLines={1}>
                {title}
              </ColoredText>
              <FontAwesomeIcon icon={[icons.faIconStyle, 'pen']} />
            </TouchableOpacity>
          ))}
          <TouchableOpacity
            onPress={() => {
              if (canCreateDashboard) {
                setVisible(true)
              } else {
                // history.push('/upselling')
                open()
              }
            }}
            style={[styles.button, { backgroundColor: colors.color3 }]}
          >
            <FontAwesomeIcon icon={[icons.faIconStyle, 'plus']} />
            <ColoredText style={styles.buttonText}>{t('dashboard.addDashboard')}</ColoredText>
          </TouchableOpacity>
          <CreateDashboardModal
            isOpen={visible}
            onClose={() => setVisible(false)}
            loading={loading}
            createDashboard={submitDashboard}
          />
        </View>
      </View>
    </PageWrapper>
  )
}

export default ManageDashboards
