import { LogBox } from 'react-native'
import { clone } from 'lodash'

const warnings = [
  // firebase sets long timers
  'Setting a timer',
]
if (LogBox) {
  LogBox.ignoreLogs(warnings)
}

export const _console = clone(console)

console.warn = (message: string) => {
  if (_console.warn && !warnings.some((warning) => message.includes(warning))) {
    _console.warn(message)
  }
}
