import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import ColoredText from '../../../components/CustomText/ColoredText'
import colors from '../../../constants/colors'
import { usePrimaryBackground } from '../../../hooks/useColors'
import { useTranslation } from '../../../hooks/useTranslation'
import useStyles from './styles'

const Chip = ({
  value,
  type,
  selected,
  onPress,
  list,
}: {
  value: string
  type: string
  selected: boolean
  onPress: (value: string, updateCategory: string) => void
  list: string
}) => {
  const styles = useStyles()
  const hightlightColor = colors.color3
  const backgroundColor = usePrimaryBackground()
  const { t } = useTranslation()

  return (
    <TouchableOpacity
      style={[
        styles.chip,
        { padding: 10, borderRadius: 50, backgroundColor: selected ? hightlightColor : backgroundColor },
      ]}
      onPress={() => onPress(type, list)}
    >
      <ColoredText>
        <>{t(value)}</>
      </ColoredText>
    </TouchableOpacity>
  )
}
const ShopSelectionDialogBody = ({
  shops,
  filter,
  updateFilter,
}: {
  shops: Array<string>
  filter: { shopList: Array<string>; notificationType: Array<string> }
  updateFilter: (value: string, updateCategory: string) => void
}) => {
  const notificationTypes = ['newsFilter', 'technicalFilter', 'customerEngagement']
  const { t } = useTranslation()

  return (
    <View>
      <ColoredText style={{ fontSize: 16, alignSelf: 'center', paddingVertical: '5%' }}>
        <>{t('title.userProfile.shops')}</>
      </ColoredText>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        {shops.map((shop) => {
          const selected = filter.shopList.includes(shop)
          return (
            <Chip type={shop} key={shop} value={shop} selected={selected} onPress={updateFilter} list={'shopList'} />
          )
        })}
      </View>

      <ColoredText style={{ fontSize: 16, alignSelf: 'center', paddingVertical: '5%' }}>
        <>{t('notifications.notifications')}</>
      </ColoredText>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <>
          {notificationTypes.map((type) => {
            const selected = filter.notificationType.includes(type)
            return (
              <Chip
                key={type}
                type={type}
                // value={'notifications.type.' + type.replace(':', '-')}
                value={t('notifications.filtertype.' + type)}
                selected={selected}
                onPress={updateFilter}
                list={'notificationType'}
              />
            )
          })}
        </>
      </View>
    </View>
  )
}
export default ShopSelectionDialogBody
