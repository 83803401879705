import React from 'react'
import { Modal, TouchableOpacity, View, TouchableWithoutFeedback, Platform } from 'react-native'
import { useSecondaryBackground } from '../../hooks/useColors'

type MobileDialogType = {
  isVisible: boolean
  showDialog: (v: boolean) => void
  children: any
}
const MobileDialog = ({ isVisible, showDialog, children }: MobileDialogType) => {
  const toggleDialog = () => showDialog(!isVisible)
  const backgroundColor = useSecondaryBackground()
  if (Platform.OS !== 'web')
    return (
      <Modal animationType="slide" transparent={true} visible={isVisible} onRequestClose={() => toggleDialog()}>
        {/* allow closing modal when clicking on overlay */}
        <TouchableOpacity
          style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 22 }}
          onPress={() => toggleDialog()}
        >
          <TouchableWithoutFeedback
            style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 22 }}
          >
            <View
              style={{
                margin: 20,
                width: '100%',
                minHeight: '35%',
                marginTop: 'auto',
                backgroundColor: backgroundColor,
                borderRadius: 20,
                paddingTop: '1%',
                alignItems: 'center',
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 4,
                elevation: 4,
                paddingBottom: '5%',
              }}
            >
              {children}
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>
    )
  return null
}

export default MobileDialog
