import { processFontFamily } from 'expo-font'
import React from 'react'
import { TextProps } from 'react-native'
import { Link } from 'react-router-dom'
import ColoredText from '../../CustomText/ColoredText'

const InternalLink = ({ to, text, style }: TextProps & { to: string; text: string }) => {
  return (
    <Link to={to} style={{ marginBottom: 15 }}>
      <ColoredText
        style={[
          {
            fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
            textDecorationLine: 'underline',
            marginBottom: 15,
          },
          style,
        ]}
      >
        {text}
      </ColoredText>
    </Link>
  )
}

export default InternalLink
