import React from 'react'
import { View } from 'react-native'
import colors from '../../../../../../constants/colors'
import { VictoryPie } from 'victory'
import { useSecondaryBackground, useTextColor } from '../../../../../../hooks/useColors'
import ColoredText from '../../../../../../components/CustomText/ColoredText'

export const ProgressCircleComponent = ({
  pagespeed_data,
  size,
}: {
  pagespeed_data: number
  isMobile: boolean
  size: any
}) => {
  const backgroundColor = useSecondaryBackground()
  const textColor = useTextColor()
  // Changes Progress color
  const processPerformanceColorForProgressColor = (num: number): string => {
    const performance = num * 100
    if (performance > 89) return colors.success
    if (performance > 49) return '#FDAB49'
    if (performance <= 49 && performance > 0) return colors.error
    //green text color red text #FC3B3A
    return '#158721'
  }

  // Changes Color of Text inside Circle
  const processPerformanceColorForText = (num: number): string => {
    const performance = num * 100
    if (performance > 89) return textColor
    if (performance > 49) return textColor
    if (performance <= 49 && performance > 0) return colors.error
    return 'black'
  }
  return (
    <View style={{ width: size.width, height: size.height - 20 }}>
      <VictoryPie
        startAngle={0}
        radius={size.height * 0.9}
        innerRadius={size.height * 0.8}
        data={[pagespeed_data, 1 - pagespeed_data]}
        colorScale={[processPerformanceColorForProgressColor(pagespeed_data), backgroundColor]}
        labels={[]}
      />
      <ColoredText
        style={{
          fontSize: 20,
          fontWeight: 'normal',
          color: processPerformanceColorForText(pagespeed_data),
          position: 'absolute',
          left: size.width * 0.35,
          top: (size.height - 20) * 0.45,
        }}
      >
        {Number(pagespeed_data * 100).toFixed(0) + '%'}
      </ColoredText>
    </View>
  )
}
