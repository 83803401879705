import React, { useState } from 'react'
import { Platform, TouchableOpacity, View, ActivityIndicator } from 'react-native'
import useStyles from '../styles'
import colors from '../../../../../../../constants/colors'
import moment from 'moment'
import { useSecondaryBackground } from '../../../../../../../hooks/useColors'
import ColoredText from '../../../../../../../components/CustomText/ColoredText'
import { functions } from '../../../../../../../firebase'

const BillRow = ({ invoice }: { invoice: Record<string, any> }) => {
  const styles = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const loadingColor = useSecondaryBackground()

  const loadPDF = async (id: string, name: string) => {
    setLoading(true)
    const getPDF = functions().httpsCallable('calls-sevdesk-getInvoiceAsPDF')
    const pdf = await (await getPDF({ invoiceId: id })).data

    if (Platform.OS === 'web') {
      const url = `data:application/pdf;base64,${pdf}`
      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.download = `${name}.pdf`
      downloadLink.click()
    }
    setLoading(false)
  }

  return (
    <View style={styles.billWrapper} key={invoice?.id}>
      <ColoredText>{moment(new Date(invoice?.created.toDate())).format('DD MMMM, YYYY')}</ColoredText>
      <ColoredText>{invoice?.invoiceNumber}</ColoredText>
      {loading ? (
        <ActivityIndicator size={'small'} color={loadingColor} />
      ) : (
        <TouchableOpacity onPress={async () => loadPDF(invoice?.invoiceId, invoice?.invoiceNumber)}>
          <ColoredText style={{ color: colors.error }}>PDF</ColoredText>
        </TouchableOpacity>
      )}
    </View>
  )
}

export default BillRow
