import { processFontFamily } from 'expo-font'
import { StyleSheet } from 'react-native'
import { useSecondaryBackground } from '../../../../hooks/useColors'

const useStyles = () => {
  const secondaryBackgroundColor = useSecondaryBackground()
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      paddingHorizontal: 10,
      paddingVertical: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
      backgroundColor: secondaryBackgroundColor,
    },
    textWrapper: {
      width: '70%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
    },
    text: {
      marginLeft: 15,
      fontSize: 18,
      fontFamily: processFontFamily('Comfortaa_400Regular') || undefined,
    },
    icon: {
      marginHorizontal: 10,
      fontSize: 25,
    },
    switch: {
      marginHorizontal: 5,
    },
  })
}

export default useStyles
