import React, { useEffect, useState } from 'react'
import useStyles from './styles'
import { View } from 'react-native'
import numbro from 'numbro'
import colors from '../../../../../constants/colors'
import { KeyFiguresT } from '../../../../../types/widgetDataTypes/types'
import icons from '../../../../../constants/icons'
import { useTranslation } from '../../../../../hooks/useTranslation'
import htmlDecode from '../../../../../utils/htmlDecode'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import BoldText from '../../../../../components/CustomText/BoldText'
import { FontAwesomeIcon } from '../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import moment from 'moment'
import { IShop } from '../../../../../types/dashboard'

interface Size {
  height: number
  width: number
}

interface PropsT {
  keyFigures?: KeyFiguresT
  shop: IShop | null
  size: Size
  // status?: StatusT Status currently not supported so fields are missing on data what leads to warnings an errors
  status?: Record<string, any>
}

const Status = ({
  status,
  keyFigures = {
    sales: { umsatz: 0, umsatz_history: 0 },
    visitors: { besucher: 0, besucher_history: 0 },
    orders: { bestellungen: 0, bestellungen_history: 0 },
    conversions: { conversion: 0, conversion_history: 0 },
  },
  shop,
  size,
}: PropsT) => {
  const { t } = useTranslation()
  const styles = useStyles()
  // const timeOfFirstRender = useRef(Date.now())

  //Use temporary while new widget is developed
  const [cachingMethod, setChachingMethod] = useState<string | null>(null)
  const [cacheEnabled, setCacheEnabled] = useState<boolean>(false)
  const [shopOnline, setShopOnline] = useState<boolean>(true)
  const [shopPerformance, setPerformance] = useState<boolean>(true)

  useEffect(() => {
    if (shop) {
      fetch(shop.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${shop.secretToken}`,
          'User-Agent':
            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17',
        },
        cache: 'no-cache',
        redirect: 'error',
        body: JSON.stringify({
          request: 'Data',
          widget: {
            statusAdvanced: {
              start_time: moment().format('x'),
            },
          },
        }),
      })
        .then(async (response) => {
          if (response.status !== 200) {
            setShopOnline(false)
          }
          setShopOnline(true)
          return response
        })
        .then(async (response) => response.json())
        .then(({ status, widget: result, error_message }) => {
          if (status === 'ok') {
            const statusData = result['statusAdvanced']
            setChachingMethod(statusData.caching_method)
            setCacheEnabled(statusData.caching_activated)
            setPerformance(+moment().format('x') - statusData.start_time < 2500)
            return result['statusAdvanced']
          }

          if (status === 'error') {
            throw new Error(error_message)
          }
        })
        .catch(() => {})
    }
  }, [status])

  useEffect(() => {
    if (shop) {
      fetch(shop.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${shop.secretToken}`,
          'User-Agent':
            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17',
        },
        cache: 'no-cache',
        redirect: 'error',
        body: JSON.stringify({
          request: 'Data',
          widget: {
            status: {},
          },
        }),
      })
        .then(async (response) => {
          return response
        })
        .then(async (response) => response.json())
        .then(({ status, widget: result, error_message }) => {
          if (status === 'ok') {
            const statusData = result['status']
            if (statusData.caching_activated === true) {
              setChachingMethod(statusData.caching_method)
              setCacheEnabled(statusData.caching_activated)
            }
          }

          if (status === 'error') {
            throw new Error(error_message)
          }
        })
        .catch(() => {})
    }
  }, [status])

  if (shop === null) {
    return null
  }
  return (
    <View style={[styles.content, size]}>
      <View style={[styles.keyFigures]}>
        {[
          {
            value: numbro(keyFigures?.sales?.umsatz ?? 0).formatCurrency({
              ...numbro.languageData().currencyFormat,
              currencySymbol: htmlDecode(shop?.currency),
              spaceSeparated: true,
              average: true,
            }),
            previous: keyFigures?.sales?.umsatz_history,
            label: t('shop.widget.key-figures.body.sales'),
            rawValue: keyFigures?.sales?.umsatz,
          },
          {
            value: numbro(keyFigures?.visitors?.besucher).format({
              output: 'number',
              spaceSeparated: true,
              average: true,
            }),
            previous: keyFigures?.visitors?.besucher_history,
            label: t('shop.widget.key-figures.body.visitors'),
            rawValue: keyFigures?.visitors?.besucher,
          },
        ].map(({ value, label, previous, rawValue }, index) => {
          const number = previous === 0 ? 0 : (rawValue - previous) / previous

          return (
            <View key={label} style={[styles.item, index === 0 ? { paddingRight: 25 } : { paddingLeft: 25 }]}>
              <View style={styles.titleWrapper}>
                <ColoredText style={styles.itemTitle}>{label}</ColoredText>
                <BoldText style={styles.itemCurrentValue}>{value}</BoldText>
              </View>
              <ColoredText style={[styles.percentage, number >= 0 ? styles.positive : styles.negative]}>
                {numbro(number).format({
                  output: 'percent',
                  mantissa: 0,
                  forceSign: true,
                })}
              </ColoredText>
            </View>
          )
        })}
      </View>

      <View style={[styles.keyFigures]}>
        {[
          {
            value: numbro(keyFigures?.orders?.bestellungen).format({
              output: 'number',
              spaceSeparated: true,
              average: true,
              totalLength: 3,
            }),
            previous: keyFigures?.orders?.bestellungen_history,
            label: t('shop.widget.key-figures.body.orders'),
            rawValue: keyFigures?.orders?.bestellungen,
          },
          {
            value: numbro((keyFigures?.conversions?.conversion ?? 1) / 100).format({
              output: 'percent',
              mantissa: 2,
              spaceSeparated: true,
              average: true,
            }),
            previous: keyFigures?.conversions?.conversion_history,
            label: t('shop.widget.key-figures.body.conversion'),
            rawValue: keyFigures?.conversions?.conversion,
          },
        ].map(({ value, label, previous, rawValue }, index) => {
          const number = previous === 0 ? 0 : (rawValue - previous) / previous

          return (
            <View key={label} style={[styles.item, index === 0 ? { paddingRight: 25 } : { paddingLeft: 25 }]}>
              <View style={styles.titleWrapper}>
                <ColoredText style={styles.itemTitle}>{label}</ColoredText>
                <BoldText style={styles.itemCurrentValue}>{value}</BoldText>
              </View>
              <ColoredText style={[styles.percentage, number >= 0 ? styles.positive : styles.negative]}>
                {numbro(number).format({
                  output: 'percent',
                  mantissa: 0,
                  forceSign: true,
                })}
              </ColoredText>
            </View>
          )
        })}
      </View>

      <View style={{}} />

      <View style={[styles.status]}>
        {[
          {
            status: shopOnline != null,
            label: t('shop.widget.status.body.shop-online'),
          },
          {
            status: shopOnline != null,
            label: t('shop.widget.status.body.maintenance'),
          },
          {
            status: shopPerformance,
            label: t('shop.widget.status.body.performance'),
          },
          {
            status: cacheEnabled,
            label: `${t('shop.widget.status.body.cache')} ${cacheEnabled ? `(${cachingMethod})` : ''}`,
          },
        ].map(({ status, label }, index) => (
          <View key={label} style={[styles.statusItem, index % 2 === 0 ? { paddingRight: 20 } : { paddingLeft: 20 }]}>
            <FontAwesomeIcon
              color={status ? colors.success : colors.error}
              icon={[icons.faIconStyle, status ? 'check-circle' : 'times-circle']}
            />
            <ColoredText style={styles.itemText}>{label}</ColoredText>
          </View>
        ))}
      </View>
    </View>
  )
}

export default Status
