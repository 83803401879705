import React from 'react'
import useDashboard from '../../../../hooks/dashboard/useDashboard'
import { isLoaded } from 'react-redux-firebase'
import { useTranslation } from 'react-i18next'
import HeaderWrapper from '../../../../layout/Header/Wrapper'
import FavoriteHeader from '../../../../layout/Header/FavoriteHeader'
import { useParams } from '../../../../components/Router/Router'

const DashboardHeader = () => {
  const { t } = useTranslation()

  const { dashboardId } = useParams()

  const dashboard = useDashboard(dashboardId ?? '-')
  console.log(`DashboardID_2: ${dashboardId}`)

  if (!isLoaded(dashboard) || !dashboard) {
    return <HeaderWrapper title={t('nav.dashboard')} />
  }

  return <FavoriteHeader title={dashboard?.title ?? ''} subtitle={'Dashboard'} />
}

export default DashboardHeader
