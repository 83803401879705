import React, { useContext } from 'react'
import { View } from 'react-native'
import { SessionContext } from '../../../../../../context/SessionContext'
import Button from '../../../../../../components/Button'
import ComponentWrapper from '../../../../../../components/ComponentWrapper'
import useStyles from './styles'
import useDocument from '../../../../../../hooks/useDocument'
import {
  currencyToSymbol,
  dashboardsForBundle,
  notificationsForBundle,
  shopsForBundle,
  userForBundle,
} from '../../../../../../utils/booking'
import colors from '../../../../../../constants/colors'
import Business from '../../../../../../assets/imgs/subscription/Business'
import Free from '../../../../../../assets/imgs/subscription/Free'
import Pro from '../../../../../../assets/imgs/subscription/Pro'
import { useNavigate } from '../../../../../../components/Router/Router'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { FontAwesomeIcon } from '../../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import { IBundle } from '../../../../../../types/booking'

const Subscription = () => {
  const { company } = useContext(SessionContext)
  const navigate = useNavigate()
  const styles = useStyles()
  const { t } = useTranslation()
  const bundle = useDocument<Record<string, any>>('bundle', company?.bundle)

  return (
    <ComponentWrapper title={t('accountOverview.subscription.your_subscription')}>
      <View style={styles.wrapper}>
        <View
          style={[
            styles.titleWrapper,
            (company?.bundle as IBundle) === IBundle.free
              ? styles.backgroundFree
              : (company?.bundle as IBundle) === IBundle.pro || company?.bundle === 'enterprise'
                ? styles.backgroundPro
                : styles.backgroundBusiness,
          ]}
        >
          <ColoredText style={styles.title}>{`dash.bar ${company?.bundle || IBundle.free}`}</ColoredText>
          <View>
            {(company?.bundle as IBundle) === IBundle.pro || company?.bundle === 'enterprise' ? (
              <Pro />
            ) : (company?.bundle as IBundle) === IBundle.business ? (
              <Business />
            ) : (
              <Free />
            )}
          </View>
        </View>
        <View style={styles.contentWrapper}>
          <View style={styles.contentAreaWrapper}>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{userForBundle(company?.bundle || '')}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{shopsForBundle(company?.bundle || '')}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{dashboardsForBundle(company?.bundle || '')}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{notificationsForBundle(company?.bundle || '')}</ColoredText>
            </View>
          </View>
          <View style={styles.divider} />
          <View style={styles.contentAreaWrapper}>
            <View style={styles.amountWrapper}>
              <ColoredText style={styles.amountText}>{`${bundle?.price?.month?.value} ${currencyToSymbol(
                bundle?.price?.month?.currency
              )} ${t('subscription.interval.text_month')}`}</ColoredText>
              <ColoredText style={styles.amountText}>{`${bundle?.price?.year?.value} ${currencyToSymbol(
                bundle?.price?.year?.currency
              )} ${t('subscription.interval.text_year')}`}</ColoredText>
            </View>
            <View style={styles.buttonWrapper}>
              <Button
                textStyle={{ color: 'white' }}
                onPress={() => navigate('/settings/subscription')}
                style={styles.button}
              >
                <>{t('accountOverview.subscription.change_subscription')}</>
              </Button>
            </View>
          </View>
        </View>
      </View>
    </ComponentWrapper>
  )
}

export default Subscription
