import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import useDocument from '../hooks/useDocument'
import useCollection from '../hooks/useCollection'
import { IDProp } from '../types/firestore'
import AsyncStorage from '@react-native-async-storage/async-storage'
// import { v4 as UUIDv4 } from 'uuid'
import useSetDocument from '../hooks/useSetDocument'
import useDeleteDocument from '../hooks/useDeleteDocument'
import { actionTypes } from 'redux-firestore'
import getApp from '../firebase'
import { useDispatch } from 'react-redux'
import * as Crypto from 'expo-crypto'
import { Dimensions, Platform } from 'react-native'
import { ISessionType } from '../types/user'
import { ICompany } from '../types/company'
import { IShop } from '../types/dashboard'
import { IUser as DashUser } from '../types/user'
import { IExtensionConfig } from '../types/extensions'

export const SessionContext = React.createContext<SessionContextValueType>({
  savePushToken: (_) => null,
  isLoaded: false,
  currentUser: null,
  signOut: async () => {},
  storeChannel: (_) => null,
})

// TODO: MOVE TO GLOBAL FIREBASE TYPES

export type SessionContextValueType = {
  isLoaded: boolean
  currentUser: firebase.User | null
  signOut: () => Promise<void>
  user?: (DashUser & IDProp) | null
  company?: (ICompany & IDProp) | null
  session?: (ISessionType & IDProp) | null
  shops?: Record<string, IShop & IDProp> | null
  extensionConfig?: Record<string, IExtensionConfig & IDProp> | null
  userIsAdmin?: boolean
  selectedChannel?: string | null
  storeChannel: (channel: string) => void
  savePushToken: (token: string) => void
}

const SessionProvider = ({ children }: PropsWithChildren<unknown>) => {
  console.debug('(init) SessionProvider')

  const [auth, setAuth] = useState<firebase.User | null>(null)
  const [channel, setChannel] = useState<string | null>(null)

  const [{ sessionID, loading, token }, setSessionState] = useState<{
    sessionID?: string
    loading: boolean
    token?: string
  }>({
    loading: true,
  })

  const dispatch = useDispatch()

  const user = useDocument<DashUser>('user', auth?.uid ?? '-')
  console.debug('(init) User->Company' + user?.company)
  const company = useDocument<ICompany>('company', user?.company ?? '-')
  console.debug('(init) SessionContext: User, Comapny', user?.id, company?.id)
  const session = useDocument<ISessionType>(`user/${auth?.uid ?? '-'}/sessions`, sessionID ?? '-')
  const userIsAdmin = company?.admin?.includes(auth?.uid || '-')
  const shops = useCollection<IShop>(
    (company?.id && `company/${company?.id}/shop`) || '',
    undefined,
    undefined,
    (company?.authorizations && (company.authorizations['general.shops'] as number)) ?? 0
  )
  const extensionConfig = useCollection<Record<string, any>>(
    (company?.id && `company/${company?.id}/extension_config`) ?? ''
  )

  const saveSession = useSetDocument<ISessionType>(`user/${auth?.uid ?? '-'}/sessions`)
  const deleteSession = useDeleteDocument(`user/${auth?.uid ?? '-'}/sessions`)

  /**
   * Update Session
   * - only update, when created or token available
   */
  const updateSession = useCallback(
    (auth: firebase.User, sessID: string, token?: string, create = false) => {
      console.debug('(updateSession) SessionContext', sessID, auth.uid)
      if (!token && !create) {
        return
      }
      saveSession(
        {
          userId: auth.uid,
          userAgent: `${navigator.userAgent}`,
          created: firebase.firestore.Timestamp.now(),
          platform: `${Platform.OS || null}`,
          platformVersion: `${Platform.Version || null}`,
          appVersion: `${require('../../package.json').version}_fix`,
          preferedLanguage: `${navigator.language || null}`,
          cookiesEnabled: navigator.cookieEnabled === true,
          dimensions: `${`${Dimensions.get('screen').width}`.split('.')[0]}x${
            `${Dimensions.get('screen').height}`.split('.')[0]
          }`,
          expoToken: token ?? '',
        },
        sessID
      )
        .then(() => console.debug('(updateSession) SessionContext - Updated Session!'))
        .catch((e) => console.debug('(updateSession) SessionContext - Update Session failed!', e))
    },
    [saveSession]
  )

  /**
   * SignOut-Process
   * 1. Delete Session Document
   * 2. Delete SessionID (AsyncStorage)
   * 3. Clear SessionState
   * 4. Firebase SignOut
   * 5. Clear Auth (firebase.User)
   * 6. Clear ReduxStore
   * 7. Redirect to /login
   */
  const signOut = useCallback(
    (sessionID?: string) => async () => {
      try {
        console.debug('(signOut) SessionContext', sessionID)
        if (sessionID) {
          await deleteSession(sessionID)
          await AsyncStorage.removeItem('@sessionID')
          setSessionState({
            sessionID: undefined,
            loading: false,
            token: undefined,
          })
        }
        //TODO switched below lines, check if that makes any problems
        await getApp().auth().signOut()
        setAuth(null)
        dispatch({ type: actionTypes.CLEAR_DATA })
        //Next steps handled in useEffect below
      } catch (e) {
        console.debug('(signOut) SessionContext - Signout Error', e)
      }
    },
    [setAuth, dispatch, deleteSession]
  )

  //useEffect necessary to be sure that authState has changed otherwise it can cause errors while signOut
  useEffect(() => {
    if (auth === null) {
      //  history.push('/login')
    }
  }, [auth])

  /**
   * Login-Process
   * 1. Get/Create SessionID
   * 2. Save SessionID (AsyncStorage)
   * 3. Save SessionState (sessID)
   * 4. Save Auth (firebase.User)
   * 5. Create Session (updateSession)
   */
  const doLogin = useCallback(
    async (user: firebase.User) => {
      try {
        console.log(`Get item:${Crypto.randomUUID()}`)
      } catch {
        console.log(`Error getting item`)
      }
      const sessID = (await AsyncStorage.getItem('@sessionID')) ?? Crypto.randomUUID()
      console.debug('(doLogin) SessionContext', user?.uid, sessID)
      try {
        updateSession(user, sessID, token, true) //Changed this line from commented line below. Idea: Session must already exist in db when changing state so theres no logout because of missing session
        await AsyncStorage.setItem('@sessionID', sessID)
        setSessionState((prev) => ({
          ...prev,
          sessionID: sessID,
        }))
        setAuth(user)
        //updateSession(user, sessID, token, true)
      } catch {
        console.debug('(signOut) SessionContext - ERROR', sessID)
      }
    },
    [updateSession, token]
  )

  /**
   * Check, if there is a *user.id* and a *sessionID*,
   *   if true => listen to Session changes, and log out, if this session gets deleted
   */
  useEffect(() => {
    console.debug(
      '(useEffect[user?.id, sessionID, signOut]) SessionContext - check active Sessions',
      user?.id,
      sessionID
    )
    if (user?.id && sessionID) {
      return getApp()
        .firestore()
        .collection(`user/${user.id}/sessions`)
        .onSnapshot(async (sessions) => {
          if (!sessions.docs.some(({ id }) => id === sessionID)) {
            console.debug('(useEffect[user?.id, sessionID, signOut]) SessionContext - logout => session deleted')
            signOut(sessionID)()
              .then(() =>
                console.debug(
                  '(useEffect[user?.id, sessionID, signOut]) SessionContext - Signed Out, due to deleted Session!'
                )
              )
              .catch((e) =>
                console.debug('(useEffect[user?.id, sessionID, signOut]) SessionContext - Signed Out Error:', e)
              )
          }
        })
    }
    return () => null
  }, [user?.id, sessionID, signOut])

  /**
   * Check if we have a sessionID, token, auth
   * => updateSession, mainly because of *token*
   */
  useEffect(() => {
    console.debug(
      '(useEffect[sessionID, auth, token, updateSession]) SessionContext - check if session needs update',
      sessionID,
      token,
      auth?.uid
    )
    if (sessionID && auth && token) {
      updateSession(auth, sessionID, token)
    }
  }, [sessionID, auth, token, updateSession])

  // useEffect(() => {
  //   console.log(`Start`)
  //   if (firebase.auth().currentUser?.uid) {
  //     console.log(`Start #1`)
  //     const uid = firebase.auth().currentUser?.uid
  //     const userStatusDatabaseRef = firebase.database().ref('/status/' + uid)
  //     const isOfflineForDatabase = {
  //       state: 'offline',
  //       last_changed: new Date(),
  //     }

  //     const isOnlineForDatabase = {
  //       state: 'online',
  //       last_changed: new Date(),
  //     }
  //     firebase
  //       .database()
  //       .ref('.info/connected')
  //       .on('value', function (snapshot) {
  //         console.log(`Start #2 ${snapshot.val()}`)
  //         if (snapshot.val() == false) {
  //           return
  //         }
  //         userStatusDatabaseRef
  //           .onDisconnect()
  //           .set(isOfflineForDatabase)
  //           .then(function () {
  //             console.log(`Connected`)
  //             userStatusDatabaseRef.set(isOnlineForDatabase)
  //           })
  //       })
  //   }
  // }, [firebase.auth().currentUser?.uid])

  /**
   * Check if still loading
   * Loading is done, when auth & user.id exists.
   */
  useEffect(() => {
    console.debug('(useEffect[user,auth]) SessionState - finish loading process', user?.id, auth?.uid)
    if (auth && user?.id) {
      setSessionState((prev) => ({ ...prev, loading: false }))
    }
  }, [auth, user?.id])

  /**
   * Check if user is authenticated and has companyClaim
   *   if false => loading = false, setAuth(null
   *   if true  => doLogin
   */
  useEffect(
    () =>
      getApp()
        .auth()
        .onAuthStateChanged((auth) => {
          if (auth && auth.uid) {
            auth.getIdTokenResult().then((result) => {
              if (result.claims.company) {
                //Check if user has already a company in claims otherwise this will cause problems in signup process (no dashboars/shops shown on first signup)
                doLogin(auth)
              }
            })
          } else {
            setSessionState((prev) => ({
              ...prev,
              loading: false,
            }))
            setAuth(null)
          }
        }),
    [doLogin]
  )

  /**
   * Check if user is authenticated and has companyClaim
   *   if false => loading = false, setAuth(null
   *   if true  => doLogin
   */
  useEffect(
    () =>
      getApp()
        .auth()
        .onIdTokenChanged((auth) => {
          if (auth) {
            auth.getIdTokenResult().then((result) => {
              if (result.claims.company) {
                //Check if user has already a company in claims otherwise this will cause problems in signup process (no dashboars/shops shown on first signup)
                doLogin(auth)
              }
            })
          } else {
            setSessionState((prev) => ({
              ...prev,
              loading: false,
            }))
            setAuth(null)
          }
        }),
    [doLogin]
  )

  const savePushToken = useCallback(
    (token: string) => setSessionState((prev) => ({ ...prev, token: token })),
    [setSessionState]
  )

  const selectChannel = useCallback(
    (channel: string) => {
      setChannel(channel)
    },
    [setChannel]
  )

  return (
    <SessionContext.Provider
      value={{
        currentUser: auth,
        isLoaded: !loading,
        signOut: signOut(sessionID),
        user,
        company,
        session,
        shops,
        extensionConfig: extensionConfig,
        userIsAdmin,
        storeChannel: selectChannel,
        selectedChannel: channel,
        savePushToken: savePushToken,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export default SessionProvider
