import React from 'react'
import { Platform, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import icons from '../../constants/icons'
import ColoredText from '../CustomText/ColoredText'
import { FontAwesomeIcon } from '../FontAwesome/ColoredFontAwesomeIcon'
import useStyles from './styles'

const Error = ({ title, message, onClose }: { title?: string; message: string; onClose: () => void }) => {
  const styles = useStyles()
  return (
    <View style={styles.card}>
      <View style={styles.errorWrapper}>
        <View style={{ minWidth: 30, marginLeft: 10, marginRight: 15 }}>
          <FontAwesomeIcon
            color="white"
            style={styles.icon}
            fontSize={30}
            size={Platform.OS === 'web' ? 'lg' : undefined}
            icon={[icons.faIconStyle, 'times-circle']}
          />
        </View>
        <View style={styles.textWrapper}>
          {title ? (
            <ColoredText numberOfLines={1} style={styles.title}>
              {title}
            </ColoredText>
          ) : null}
          <ColoredText style={styles.message}>{message}</ColoredText>
        </View>
        <TouchableOpacity onPress={() => onClose()} containerStyle={styles.button}>
          <ColoredText
            numberOfLines={1}
            style={[{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }, styles.message]}
          >
            Close
          </ColoredText>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default Error
