import { View } from 'react-native'
import useCurrentBundleBooking from '../../hooks/booking/useCurrentBundleBooking'
import useDocument from '../../hooks/useDocument'
import React, { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import useStyles from './styles'
import colors from '../../constants/colors'
import { useTranslation } from '../../hooks/useTranslation'
import { FontAwesomeIcon } from '../FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../CustomText/ColoredText'
import { ICompany } from '../../types/company'

const AttentionNotice = () => {
  const { user } = useContext(SessionContext)
  const currentBooking = useCurrentBundleBooking()
  const isMigration = currentBooking?.isMigration || false
  const company = useDocument<ICompany>(`company`, user?.company)
  const hasAbo = company?.aboCloud?.aboId !== undefined
  const styles = useStyles()
  const { t } = useTranslation()

  if (!isMigration || hasAbo) {
    return <></>
  }

  return (
    <View style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row' }}>
      <FontAwesomeIcon icon={'exclamation-triangle'} color={colors.highlightDark} />
      <ColoredText style={styles.textBold}>
        <>{t('subscription.action_required')}</>
      </ColoredText>
      <ColoredText style={styles.text}>
        <>{t('subscription.action_required_text')}</>
      </ColoredText>
    </View>
  )
}

export default AttentionNotice
