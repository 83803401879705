import numbro from 'numbro'
import React from 'react'
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory'
import colors from '../../../constants/colors'
import { View } from 'react-native'
import { useTextColor } from '../../../hooks/useColors'
import moment from 'moment'
import { useFontFamily } from '../../../hooks/useFontFamily'
import { DurationInputArg2 } from 'moment'

interface BarChartProps {
  data: Array<number>
  size: {
    width: number
    height: number
  }
  showAxis?: boolean
  displayValues?: boolean
  interval?: string
}

/**
 * creates labels for day and month intervals
 *
 * @param interval the type of interval that is used in text ( days | months)
 * @param amount the amount of days or months that make up one interval
 * @param format a format string that is used to format the result
 * @returns array of four labels one for each interval
 */
const getMarkings = (interval: DurationInputArg2, amount: number, format: string): Array<string> => {
  let markings: Array<string> = []
  for (let i = 0; i < 4; i++) {
    if (interval === 'days') {
      markings = markings.concat(
        `${moment()
          .subtract(amount * (i + 1) - 1, interval)
          .format(format)}\n- ${moment()
          .subtract(amount * i, interval)
          .format(format)}`
      )
    } else {
      markings = markings.concat(
        moment()
          .subtract(amount * i, interval)
          .format(format)
      )
    }
  }
  return markings.reverse()
}

/**
 * determines wich kind of interval is currently selected and returns the apropriate labels
 *
 * @param interval the selected interval (e.g. D30, M5, ...)
 * @returns array of four labels one for each interval
 */
export const getIntervalType = (interval: string): Array<string> => {
  const characters = interval.split('')
  const type = characters[0]
  const size = +characters.slice(1).join('')
  switch (type[0]) {
    case 'D':
      return getMarkings('days', size, 'DD.MM')
    case 'Q':
      return [
        'Q' + ((size % 4) + 1).toString(),
        'Q' + (((size + 1) % 4) + 1).toString(),
        'Q' + (((size + 2) % 4) + 1).toString(),
        'Q' + (((size + 3) % 4) + 1).toString(),
      ]
    case 'M':
      return getMarkings('months', 1, 'MMM')
    case 'Y':
      return [(+size - 3).toString(), (size - 2).toString(), (size - 1).toString(), size.toString()]
    default:
      return []
  }
}

const BarChart = ({ data, showAxis = false, size, displayValues = true, interval }: BarChartProps) => {
  const textColor = useTextColor()
  const amountOfData = data?.length
  const { regular } = useFontFamily()
  let counter = 0
  const intervalMarkings = getIntervalType(interval)
  const preparedData = data?.map((v) => {
    counter++
    return {
      x: `${intervalMarkings[counter - 1]}`,
      y: v,
      key: counter,
    }
  })

  return (
    <View
      style={{
        width: size.width,
        height: size.height,
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 5,
      }}
    >
      <VictoryChart domainPadding={{ x: 40, y: 0 }} width={size.width + 280} height={size.height + 155}>
        <VictoryBar
          x={'key'}
          y={'y'}
          data={preparedData}
          labels={data.map((v) => `${numbro(v).format({ mantissa: 1, spaceSeparated: true, average: true })}€`)}
          categories={{ x: ['1', '2', '3', '4'] }}
          alignment={'middle'}
          barRatio={1.1}
          barWidth={80}
          style={{
            data: { fill: ({ index }) => (index === amountOfData - 1 ? colors.color3 : colors.color2), fillOpacity: 1 },
            labels: { fontSize: 22, fontFamily: regular, fontWeight: 'lighter' },
          }}
        />

        <VictoryAxis
          tickValues={[0, 1, 2, 3]}
          tickFormat={(index) => {
            if (preparedData === undefined) return ''
            return preparedData[index - 1]?.x ?? ''
          }}
          style={{
            ...(!showAxis && { axis: { stroke: 'transparent' } }),
            tickLabels: {
              fill: textColor,
              fontFamily: regular,
              fontSize: 20,
              ...(displayValues === false && { display: 'none' }),
            },
          }}
        />
      </VictoryChart>
    </View>
  )
}

export default BarChart
