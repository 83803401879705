import numbro from 'numbro'
import React from 'react'
import Svg from 'react-native-svg'
import { VictoryArea, VictoryAxis, VictoryChart, VictoryScatter } from 'victory'
import colors from '../../../../../../constants/colors'
import { useHighlightColor, useTextColor } from '../../../../../../hooks/useColors'
import { Themes } from '../../../../../../types/dashbar/theme'
import { useTheme } from '../../../../../../utils/types/theme'
import { useFontFamily } from '../../../../../../hooks/useFontFamily'
import { getIntervalType } from '../../../../../../components/VictoryCharts/Bar'

interface LineChartProps {
  data: Array<number>
  size: {
    width: number
    height: number
  }
  interval: string
  showAxis?: boolean
  displayValues?: boolean
}
const AreaChart = ({ data, showAxis = false, size, displayValues = true, interval }: LineChartProps) => {
  const textColor = useTextColor()
  const lineColor = useHighlightColor()
  const { regular } = useFontFamily()
  const theme = useTheme()
  const fillColor = theme === Themes.Dark ? colors.color4 : colors.color2
  const labels = getIntervalType(interval)
  let counter: number = 0
  const preparedData = data.map((v) => {
    counter++
    return {
      x: labels[counter - 1],
      y: v,
    }
  })
  const amountOfData = preparedData.length

  const screenWidth = size.width
  const screenHeight = size.height

  return (
    <Svg style={{ width: '100%', height: '100%' }} viewBox={`0,0,${screenWidth},${screenHeight}`}>
      <VictoryChart
        domainPadding={{ y: 30, x: 22 }}
        standalone={false}
        padding={{ top: 0, bottom: 40, right: 0, left: 0 }}
        width={screenWidth}
        height={screenHeight}
      >
        <VictoryArea
          width={screenWidth}
          labels={preparedData.map((data) => {
            return displayValues
              ? `${numbro(data.y).format({
                  mantissa: 1,
                  spaceSeparated: true,
                  average: true,
                })}€`
              : ''
          })}
          height={screenHeight}
          standalone={false}
          scale={'linear'}
          data={preparedData}
          interpolation="monotoneX"
          style={{
            labels: {
              fontFamily: regular,
              fontSize: 12,
            },
            data: {
              fill: fillColor,
              fillOpacity: theme === Themes.Dark ? 0.5 : 0.2,
              stroke: lineColor,
              strokeWidth: 3,
            },
          }}
        />
        <VictoryScatter
          width={screenWidth}
          height={screenHeight}
          size={5}
          standalone={false}
          style={{
            data: { fill: ({ index }) => (index === amountOfData - 1 ? colors.color3 : lineColor), fillOpacity: 1 },
          }}
          data={preparedData}
        />

        <VictoryAxis
          standalone={false}
          width={screenWidth}
          height={screenHeight}
          padding={{ top: 0 }}
          style={{
            ...(!showAxis && { axis: { stroke: 'transparent' } }),
            tickLabels: { fill: textColor, fontFamily: regular, fontSize: 11 },
          }}
        />
      </VictoryChart>
    </Svg>
  )
}

export default AreaChart
