import React from 'react'
import { View } from 'react-native'
import { isLoaded } from 'react-redux-firebase'
import useDocument from '../../../hooks/useDocument'
import Button from '../../Button'
import useStyles from './styles'
import * as Clipboard from 'expo-clipboard'
import ColoredText from '../../CustomText/ColoredText'

const CouponRow = ({ couponId }: { couponId?: string }) => {
  const styles = useStyles()
  const coupon = useDocument<Record<string, any>>(`coupons`, couponId)

  if (!isLoaded(coupon) || !couponId) {
    return null
  }

  return (
    <View style={styles.couponRowWrapper}>
      <ColoredText style={{ textAlign: 'center' }}>{`${coupon?.name || ''}`}</ColoredText>
      <Button
        style={styles.button}
        onPress={async () => {
          await Clipboard.setStringAsync(`${coupon.code || ''}`)
        }}
      >{`Code kopieren`}</Button>
    </View>
  )
}

export default CouponRow
