import { useCallback, useContext } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { SessionContext } from '../../context/SessionContext'
import { IWidget } from '../../types/dashboard'

const useRemoveWidget = (dashboardId: string) => {
  const firestore = useFirestore()
  const { user } = useContext(SessionContext)

  return useCallback(
    async (widgetToRemove: IWidget, allWidgetsInDashboard: Array<IWidget>) => {
      const updatedWidgets = allWidgetsInDashboard.filter((widget) => widget.id !== widgetToRemove.id)

      return firestore
        .collection(`company/${user?.company ?? '-'}/dashboard`)
        .doc(dashboardId)
        .update({
          widgets: updatedWidgets,
        })
    },
    [dashboardId, firestore, user?.company]
  )
}

export default useRemoveWidget
