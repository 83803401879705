import Button from '../../components/Button'
import { AuthProvider as AuthProviderT, OAuthCredential as OAuthCredentialT } from '@firebase/auth-types'
import { Platform } from 'react-native'
import firebase from 'firebase/compat/app'
import * as AppleAuthentication from 'expo-apple-authentication'
// import { sha256 } from 'js-sha256'
import React from 'react'
import * as Crypto from 'expo-crypto'
import useStyles from '../../components/Form/login-styles'
// import AppleAnmeldenButton from '../../assets/imgs/providers/AppleAnmeldenButton'
import AppleLoginSvg from '../../assets/imgs/login/AppleLogin'

interface IProps {
  handleProviderLogin: (provider: AuthProviderT) => void
  handleCredentialLogin: (credential: OAuthCredentialT) => void
}

const AppleLogin = ({ handleProviderLogin, handleCredentialLogin }: IProps) => {
  //const [loading, setLoading] = useState<boolean>(false)
  const loginStyles = useStyles()

  const handleAppleLogin = async () => {
    const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com')
    if (Platform.OS === 'web') {
      handleProviderLogin(appleAuthProvider)
    } else if (Platform.OS === 'ios') {
      const csrf = Math.random().toString(36).substring(2, 15)

      const nonce = Math.random().toString(36).substring(2, 10)

      // const hashedNonce = sha256(nonce)
      const hashedNonce = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, nonce)

      const appleCredential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
        state: csrf,
        nonce: hashedNonce,
      })

      const { identityToken, state } = appleCredential

      if (identityToken && state === csrf) {
        const credential = appleAuthProvider.credential({
          idToken: identityToken,
          rawNonce: nonce, // nonce value from above
        })

        handleCredentialLogin(credential)
      }
    }
  }
  return (
    <Button
      onPress={async () => handleAppleLogin()}
      style={[
        loginStyles.buttonOutlined,
        {
          flexDirection: 'column',
          justifyContent: 'center',

          alignContent: 'center',
          alignItems: 'center',
          paddingVertical: 5,
          paddingTop: 3,
          backgroundColor: 'black',
        },
      ]}
      textStyle={loginStyles.buttonOutlinedText}
      disabled={false}
    >
      <AppleLoginSvg />
    </Button>
  )

  // return (
  //   <>
  //     <Button
  //       style={{
  //         width: 180,
  //         height: 45,
  //         padding: 0,
  //         flex: 1,
  //         justifyContent: 'center',
  //         alignContent: 'center',
  //         alignItems: 'center',
  //       }}
  //       onPress={() => handleAppleLogin()}
  //       disabled={false}
  //     >
  //       {/* <FontAwesomeIcon style={{ color: 'white' }} icon={['fab', 'apple']} />
  //     <ColoredText style={{ color: 'white' }}>
  //       <> {t('button.baseLogin.loginWith')} Apple</>
  //     </ColoredText> */}
  //       <AppleAnmeldenButton />
  //     </Button>
  //   </>
  // )
}

export default AppleLogin
