import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Swipeable } from 'react-native-gesture-handler'
import colors from '../../../constants/colors'
import { faTrashAlt, IconName } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import useDeviceType from '../../../hooks/useDeviceType'
import { getTimestamp } from './helper'
import icons from '../../../constants/icons'
import useStyles from './styles'
import { useNavigate } from '../../../components/Router/Router'
import { usePrimaryBackground } from '../../../hooks/useColors'
import { FontAwesomeIcon } from '../../../components/FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../../../components/CustomText/ColoredText'
import { NotType } from '../../../types/notification'

function getPath(notificationData: Record<string, any>, shopName: string) {
  console.debug(`Try to get Path`)
  //if (notificationData.path) return notificationData.path
  switch (notificationData.type) {
    case 'shop:order': {
      let path = `/analytics/orders/${shopName}`
      if (notificationData.id) path += '/' + notificationData.id
      return path
    }
    case 'company:invitation:received':
      if (notificationData.invitationCode) {
        console.debug(`Open Invitation Notification`)
        return `settings/accountOverview/${notificationData.invitationCode}`
      }
      break
    default:
      console.debug(`Open default notification`)
      return notificationData.path
  }
}

const NotificationView = ({
  notification,
  notificationID,
  shopName,
  onDelete,
}: {
  notification: NotType
  notificationID: string
  shopName: string
  onDelete: (id: string, shopName: string) => void
}) => {
  const navigate = useNavigate()
  const primaryBackground = usePrimaryBackground()
  const device = useDeviceType()
  const styles = useStyles()

  const NotificationIcon = ({ icon = 'info' }: { icon?: string }) => {
    let displayIcon: IconName = icon.trim() !== '' ? (icon as IconName) : ('info' as IconName)
    if (icon == 'cart-circle-xmark' || icon == 'cart-circle-plus') displayIcon = 'cart-plus'
    if (icon == 'cloud-exclamation') displayIcon = 'exclamation-triangle' as IconName
    if (icon == 'cloud-check') displayIcon = 'check-circle' as IconName
    if (icon == 'person-digging') displayIcon = 'cog' as IconName

    return (
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <View
          style={{
            height: 45,
            width: 45,
            borderRadius: 50,
            backgroundColor: 'orange',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={[icons.faIconStyle, displayIcon]}
            style={{ backgroundColor: 'orange', alignSelf: 'center' }}
            //size={'25x'} causes error  Failed prop type: Invalid prop `size` of type `string` supplied to `FontAwesomeIcon`, expected `number`.
            color={'white'}
          />
        </View>
      </View>
    )
  }

  const NotificationBody = () => {
    return (
      <View style={{ paddingLeft: 10, maxWidth: '85%' }}>
        {notification.createdAt && (
          <ColoredText style={{ fontSize: 10, marginLeft: 5 }}>
            {notification?.receivedAt && getTimestamp(notification?.receivedAt)}
          </ColoredText>
        )}
        <ColoredText style={{ fontSize: 20, paddingTop: 5, marginLeft: 5 }}>{notification.title}</ColoredText>
        <ColoredText style={{ margin: 5, flexWrap: 'wrap' }}>{notification.body}</ColoredText>
      </View>
    )
  }

  const RightSwipeActions = () => {
    return (
      <TouchableOpacity
        style={{
          width: '25%',
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
          backgroundColor: colors.error,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={() => onDelete(notificationID, shopName)}
      >
        <FontAwesomeIcon color={primaryBackground} icon={faTrashAlt} />
      </TouchableOpacity>
    )
  }

  if (device === 'web') {
    return (
      <View style={styles.notificationCard}>
        <TouchableOpacity onPress={() => navigate(getPath(notification.data ?? {}, shopName))}>
          <View
            style={{
              backgroundColor: 'transparent',
              padding: 10,
              flexDirection: 'row',
              borderRadius: 16,
            }}
          >
            <NotificationIcon icon={notification?.data?.icon} />
            <NotificationBody />
            <TouchableOpacity
              style={{ position: 'absolute', right: 10, top: 10 }}
              onPress={() => onDelete(notificationID, shopName)}
            >
              <FontAwesomeIcon color={'black'} icon={faTimes} />
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
  return (
    <View style={styles.notificationCard}>
      <Swipeable renderRightActions={RightSwipeActions}>
        <TouchableOpacity onPress={() => navigate(getPath(notification.data ?? {}, shopName))}>
          <View
            style={{
              backgroundColor: 'transparent',
              padding: 10,
              flexDirection: 'row',
              borderRadius: 15,
            }}
          >
            <NotificationIcon icon={notification?.data?.icon} />
            <NotificationBody />
          </View>
        </TouchableOpacity>
      </Swipeable>
    </View>
  )
}

export default NotificationView
