import { useFirestore } from 'react-redux-firebase'
import { useCallback, useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { Languages } from '../../types/language'

const useStoreLanguage = () => {
  const { currentUser } = useContext(SessionContext)
  const firestore = useFirestore()

  return useCallback(
    async (language: Languages) => {
      await firestore.doc(`user/${currentUser?.uid}`).set({ preferences: { language: language } }, { merge: true })
    },
    [firestore]
  )
}

export default useStoreLanguage
