import React, { ReactNode, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View } from 'react-native'
import { SessionContext } from '../../context/SessionContext'
import { functions } from '../../firebase'
import useAllowedUser from '../../hooks/booking/useAllowedUser'
import useCollection from '../../hooks/useCollection'
import Avatar from '../Avatar'
import Button from '../Button'
import ColoredText from '../CustomText/ColoredText'
import LoadingScreen from '../LoadingScreen'
import ModalPopUp from '../Modal'
import { useNavigate } from '../Router/Router'
import useStyles from './styles'
import { IUser } from '../../types/user'

const ContingentWrapper = ({ children }: { children: ReactNode }) => {
  const { company, userIsAdmin } = useContext(SessionContext)
  const styles = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const modalRef = useRef<any>()
  const [deletingUser, setDeletingUser] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<Array<string>>([])
  const allowedUser = useAllowedUser()
  const deleteUser = functions().httpsCallable('calls-user-deleteUser')
  const currentUserCount = company?.users.length || 0
  const users = useCollection<IUser>('user', [['id', 'in', company?.users?.length ? company?.users : ['-']]])

  //Possible fix for above error
  // useEffect(()=>{
  //   firestore.collection('user').where(firebase.firestore.FieldPath.documentId() as unknown as string,
  //   'in',
  //   company?.users?.length ? company?.users : ['-']).get().then((user)=>{

  //   })

  // },[company?.users])

  const toggleIncludedUser = (userId: string) => {
    const index = selectedUser.indexOf(userId, 0)
    if (index > -1) {
      setSelectedUser([
        ...selectedUser.filter((value) => {
          return value !== userId
        }),
      ])
    } else {
      if (selectedUser.length < allowedUser) {
        setSelectedUser([...selectedUser, userId])
      }
    }
  }

  const deleteExcludedUser = async () => {
    setDeletingUser(true)
    await Promise.all(
      Object.entries(users ? users : {})
        .filter(([, e]) => {
          return !selectedUser?.includes(e.id)
        })
        .map(async ([, e]) => {
          console.debug(`Deleting user: ${e.id}`)
          try {
            await deleteUser({ userId: e.id })
          } catch {
            if (modalRef && modalRef.current) modalRef.current.setVisible(false)
            setDeletingUser(false)
          }
          return true
        })
    )
    if (modalRef && modalRef.current) modalRef.current.setVisible(false)
    setDeletingUser(false)
  }

  if (currentUserCount > allowedUser) {
    if (userIsAdmin) {
      return (
        <>
          <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <ColoredText style={styles.title}>{t('contingentWrapper.too_many_user')}</ColoredText>
            <ColoredText style={styles.subtitle}>
              {t('contingentWrapper.choose_user', { count: allowedUser })}
            </ColoredText>
            <View style={styles.userWrapper}>
              <ColoredText style={{ width: '100%', fontWeight: 'bold', textAlign: 'center', marginVertical: 10 }}>
                {t('contingentWrapper.user_left', { left: +allowedUser - selectedUser.length })}
              </ColoredText>
              {Object.entries(users || {}).map(([, value]) => {
                return (
                  <TouchableOpacity
                    key={value.id}
                    style={[styles.user, selectedUser?.includes(value.id) ? styles.userSelected : null]}
                    onPress={() => toggleIncludedUser(value.id)}
                  >
                    <Avatar style={styles.avatar} photoURL={value.profileImage || ''} />
                    <ColoredText style={selectedUser?.includes(value.id) ? styles.textSelected : null}>
                      {value.email}
                    </ColoredText>
                  </TouchableOpacity>
                )
              })}
            </View>
            <View style={styles.buttonWrapper}>
              <Button
                onPress={() => {
                  if (modalRef && modalRef.current) modalRef.current.setVisible(true)
                }}
                style={styles.button}
                textStyle={{ color: 'white' }}
              >
                {t('contingentWrapper.choose')}
              </Button>
              <Button
                onPress={() => {
                  navigate(`/settings/subscription`, { replace: true })
                }}
              >
                {t('contingentWrapper.upgrade')}
              </Button>
            </View>
          </View>
          <ModalPopUp ref={modalRef} title={`${t('contingentWrapper.modal.title')}`}>
            {deletingUser ? (
              <LoadingScreen />
            ) : (
              <View>
                <ColoredText>{t('contingentWrapper.modal.text')}</ColoredText>
                <ColoredText>{t('contingentWrapper.modal.upgrade_text')}</ColoredText>
                <Button
                  style={styles.button}
                  textStyle={{ color: 'white' }}
                  onPress={() => {
                    navigate(`/settings/subscription`, { replace: true })
                    if (modalRef && modalRef.current) modalRef.current.setVisible(false)
                  }}
                >
                  {t('contingentWrapper.modal.upgrade')}
                </Button>
                <Button
                  style={styles.deleteButton}
                  textStyle={{ color: 'white' }}
                  onPress={async () => deleteExcludedUser()}
                >
                  {t('contingentWrapper.modal.remove_user')}
                </Button>
              </View>
            )}
          </ModalPopUp>
        </>
      )
    } else {
      return (
        <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <ColoredText style={styles.title}>{t('contingentWrapper.needs_config')}</ColoredText>
        </View>
      )
    }
  }
  return <>{children}</>
}

export default ContingentWrapper
