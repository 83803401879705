import { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../../../context/SessionContext'
import getApp from '../../../firebase'
import moment from 'moment'
import { reportValid, reportViewedByUser } from '../helper'
import { Platform, View } from 'react-native'
import { useNavigate } from '../../Router/Router'
import { arrayUnion } from 'firebase/firestore'
const REPORT_PLATFORMS = ['android', 'ios']

const AnnualReviewProvider = () => {
  const { company, currentUser, shops } = useContext(SessionContext)
  const [hasReviews, setHasReviews] = useState<boolean>(false)
  const navigate = useNavigate()
  console.log(`[AnnualReviewProvider]`)

  useEffect(() => {
    const checkReports = async () => {
      const year = moment().year() - 1
      for (const shop of Object.keys(shops || {})) {
        console.log(`[AnnualReviewProvider] Checking ${shop} for report`)
        const reportDoc = await getApp()
          .firestore()
          .collection(`company/${company?.id}/shop/${shop}/annual_review`)
          .doc(`${year}`)
          .get()
        if (reportDoc.exists && reportValid(reportDoc.data()) && !(await reportViewedByUser(year, currentUser?.uid))) {
          setHasReviews(true)
          console.log(`Has Report`)
          return
        }
      }
    }
    if (
      REPORT_PLATFORMS.includes(Platform.OS) &&
      currentUser?.uid &&
      shops !== null &&
      Object.keys(shops || {}).length > 0
    ) {
      console.log(`[AnnualReviewProvider] Checking for reports`)
      checkReports()
    }
  }, [company?.id, shops, currentUser?.uid])

  useEffect(() => {
    const openReport = async () => {
      await getApp()
        .firestore()
        .collection(`user`)
        .doc(currentUser?.uid)
        .set({ annual_reports: arrayUnion(moment().year() - 1) }, { merge: true })
      navigate(`/annual_review/${moment().year() - 1}`)
    }
    if (REPORT_PLATFORMS.includes(Platform.OS) && hasReviews === true) {
      openReport()
    }
  }, [hasReviews])

  return <View />
}

export default AnnualReviewProvider
