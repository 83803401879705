import numbro from 'numbro'
import React from 'react'
import { Platform, View } from 'react-native'
import Svg from 'react-native-svg'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLine } from 'victory'
import colors from '../../../constants/colors'
import { useTextColor } from '../../../hooks/useColors'
import { useTranslation } from '../../../hooks/useTranslation'
import { Themes } from '../../../types/dashbar/theme'
import { useTheme } from '../../../utils/types/theme'
import ColoredText from '../../CustomText/ColoredText'
import BluredText from '../../CustomText/BluredText'

interface MixedChartProps {
  data?: Array<{ rate: number; sale: number; visitors: number }>
  size: {
    width: number
    height: number
  }
  showAxis?: boolean
  displayLegend?: boolean
}

const MixedChart = ({ size, data, displayLegend = true }: MixedChartProps) => {
  const screenWidth = size.width
  const screenHeight = size.height
  const lineColorVisitor = colors.highlightLight
  const lineColorSales = colors.highlightDark
  const theme = useTheme()
  const { t } = useTranslation()
  const color = useTextColor()

  if (!data) return null
  // console.log(data)
  // console.log(typeof data)
  const rateList = data.map(({ rate }) => rate)

  const maxRateList = numbro(Math.max(...rateList)).format({
    average: true,
    totalLength: 3,
  })
  // Normalizes Line Charts
  const visitorsList = data.map(({ visitors }) => visitors)
  const maxVisitorsList = numbro(Math.max(...visitorsList)).format({
    average: true,
    totalLength: 3,
  })
  const normalizedVisitorsList = data.map(({ visitors }) => {
    if (Math.max(...visitorsList) === 0) {
      return 0
    }
    return visitors / Math.max(...visitorsList)
  })

  const saleList = data.map(({ sale }) => sale)
  const maxSaleList = numbro(Math.max(...saleList)).format({
    average: true,
    totalLength: 3,
  })
  const normalizedSaleList = data.map(({ sale }) => {
    if (Math.max(...saleList) === 0) {
      return 0
    }
    return sale / Math.max(...saleList)
  })

  return (
    <>
      <Svg style={{ width: '100%', height: '100%' }} viewBox={`0,0,${screenWidth},${screenHeight}`}>
        <VictoryChart
          domainPadding={{ y: 20, x: 0 }}
          standalone={false}
          padding={{ top: 0, bottom: 10, right: 0, left: 0 }}
          width={screenWidth}
          height={screenHeight}
          // style={{ background: { fill: 'white' } }}
        >
          <VictoryBar
            data={rateList}
            alignment={'middle'}
            barRatio={1}
            style={{ data: { fill: colors.color2, fillOpacity: theme === Themes.Dark ? 1 : 1 } }}
          />
          <VictoryAxis
            standalone={false}
            style={{
              axis: { stroke: 'transparent' },
              ticks: { stroke: 'transparent' },
              tickLabels: { fill: 'transparent' },
            }}
          />
        </VictoryChart>
        <VictoryChart
          standalone={false}
          padding={{ top: 0, bottom: 10, right: 0, left: 0 }}
          width={screenWidth}
          height={screenHeight}
          // style={{ background: { fill: 'white' } }}
        >
          <VictoryLine
            domainPadding={{ y: 5, x: 0 }}
            singleQuadrantDomainPadding={false}
            width={screenWidth}
            height={screenHeight}
            standalone={false}
            data={normalizedSaleList}
            interpolation="natural"
            style={{
              data: {
                stroke: lineColorSales,
                strokeWidth: 3,
              },
            }}
          />
          <VictoryLine
            domainPadding={{ y: 5, x: 0 }}
            singleQuadrantDomainPadding={false}
            width={screenWidth}
            height={screenHeight}
            standalone={false}
            data={normalizedVisitorsList}
            interpolation="natural"
            style={{
              data: {
                stroke: lineColorVisitor,
                strokeWidth: 3,
              },
            }}
          />
          <VictoryAxis
            standalone={false}
            style={{
              axis: { stroke: 'transparent' },
              ticks: { stroke: 'transparent' },
              tickLabels: { fill: 'transparent' },
            }}
          />
        </VictoryChart>
      </Svg>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View
            style={{
              backgroundColor: colors.color2,
              height: 10,
              width: 10,
              borderRadius: 2,
              alignSelf: 'center',
              marginRight: Platform.select({ default: undefined, web: 4 }),
            }}
          />
          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginHorizontal: '1.5%',
            }}
          >
            <ColoredText
              style={{
                fontSize: 12,
              }}
            >
              <>{t('shop.widget.conversion.body.conversion')}</>
            </ColoredText>
            {displayLegend ? (
              <ColoredText
                style={{
                  fontSize: 12,
                  color: color,
                }}
              >
                {`max ${maxRateList}%`}
              </ColoredText>
            ) : (
              <BluredText
                style={{
                  fontSize: 12,
                  color: color,
                }}
                text={`max ${maxRateList}%`}
              />
            )}
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View
            style={{
              backgroundColor: colors.color3,
              height: 10,
              width: 10,
              borderRadius: 2,
              alignSelf: 'center',
              marginRight: Platform.select({ default: undefined, web: 4 }),
            }}
          />
          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginHorizontal: '1.5%',
            }}
          >
            <ColoredText
              style={{
                fontSize: 12,
              }}
            >
              <>{t('shop.widget.conversion.body.sales')}</>
            </ColoredText>
            {displayLegend ? (
              <ColoredText
                style={{
                  fontSize: 12,
                  color: color,
                }}
              >
                {`max ${maxSaleList}€`}
              </ColoredText>
            ) : (
              <BluredText
                style={{
                  fontSize: 12,
                  color: color,
                }}
                text={`max ${maxSaleList}€`}
              />
            )}
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View
            style={{
              backgroundColor: colors.color1,
              height: 10,
              width: 10,
              borderRadius: 2,
              alignSelf: 'center',
              marginRight: Platform.select({ default: undefined, web: 4 }),
            }}
          />
          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginHorizontal: '1.5%',
            }}
          >
            <ColoredText
              style={{
                fontSize: 12,
              }}
            >
              <>{t('shop.widget.conversion.body.visitors')}</>
            </ColoredText>
            {displayLegend ? (
              <ColoredText
                style={{
                  fontSize: 12,
                  color: color,
                }}
              >
                max {maxVisitorsList}
              </ColoredText>
            ) : (
              <BluredText
                style={{
                  fontSize: 12,
                  color: color,
                }}
                text={`max ${maxVisitorsList}`}
              />
            )}
          </View>
        </View>
      </View>
    </>
  )
}

export default MixedChart
