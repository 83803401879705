import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import Widget from '../../../../components/WidgetNoAPI'
import { useTranslation } from 'react-i18next'
import { Image, Platform, View } from 'react-native'
import { functions } from '../../../../../firebase'
import { calculateDatesOfInterval } from './funcs'
import { httpsCallable } from 'firebase/functions'
import { AmazonBuyerTypeSalesObject } from './types'
import IntervalSelection from '../../../../components/IntervalSelection'
import useIntervals from '../../../../../hooks/widget/useIntervals'
import { useUpdateWidgetContext } from '../../../../../types/Widget'
import SalesSkeleton from './SalesSkeleton'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import AmazonAreaChart from './AmazonAreaChart'
import { useFirestore } from 'react-redux-firebase'
import { SessionContext } from '../../../../../context/SessionContext'

const AmazonSales = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const [rawData, setRawData] = useState<Array<AmazonBuyerTypeSalesObject>>([])
  const [salesAmazonDataInterval, setSalesAmazonDataInterval] = useState<Array<number>>([])
  const [loading, setLoading] = useState<boolean>(true)
  const firestore = useFirestore()
  const { company } = useContext(SessionContext)
  const updateWidget = useUpdateWidgetContext()
  const [error, setError] = useState<{ text: string; link: string | null } | null>(null)
  const getOrderMetricsFunc = httpsCallable(functions(), 'widgets-amazon-ordermetrics')
  const [needsConfig, setNeedsConfig] = useState<boolean>(false)

  const onIntervalChange = useCallback(
    (newInterval: string) => {
      const newWidget = {
        ...widget,
        settings: {
          ...widget.settings,
          interval: newInterval,
        },
      }
      updateWidget(newWidget)
    },
    [widget, updateWidget]
  )
  const intervals = useIntervals()

  useEffect(() => {
    firestore
      .collection(`company/${company?.id}/extension_config`)
      .doc('amazon')
      .get()
      .then((doc) => {
        if (!doc.exists || !doc.data()) {
          setNeedsConfig(true)
          return
        }
        if (!doc.data()?.oauth || !doc.data()?.oauth.token) {
          setNeedsConfig(true)
          return
        }
        setNeedsConfig(false)
      })
  }, [firestore, company])

  useEffect(() => {
    getOrderMetricsFunc({})
      .then((data: Record<string, any>) => {
        if (data.data.length === 0) {
          setError({ text: `Es konnten keine Daten bei Amazon gefunden werden.`, link: null })
        } else {
          setRawData(data.data)
        }
      })
      .catch(() => {
        setError({ text: `Es konnten keine Daten von Amazon abgerufen werden.`, link: null })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    trimDataToInterval()
  }, [widget.settings.interval, rawData])

  const trimDataToInterval = () => {
    if (widget.settings.interval === undefined) return
    const dates = calculateDatesOfInterval(widget.settings.interval)
    if (dates?.dateEnd === undefined || dates.dateStart === undefined) return
    if (rawData === null || rawData.length === 0) return

    const newData: Array<AmazonBuyerTypeSalesObject> = []
    // for (let i = 0; i < rawData.length; i++) {
    for (const rawDataItem of rawData) {
      const dateDB1 = new Date(rawDataItem.interval.split('--')[0])

      if (dateDB1 < dates?.dateEnd && dateDB1 >= dates?.dateStart) {
        newData.push(rawDataItem)
      }
      // über Daten laufen und vergleichen, ob Datum zwischen Date1 und Date2 liegt, dann gefilterte Daten zurückgeben
      // Daten in der Datenbank sind MitteleuropSommerzeit
    }

    // interval Anfangsgrenze kleiner, als erster Datensatz, dann mit 0er füllen, damit Graph nicht missverständlich ist
    const i = 0
    const tmpDate = new Date(rawData[i].interval.split('--')[0])
    while (dates.dateStart < tmpDate) {
      // interval wird nicht berücksichtigt, wird nur sicherheitshalber gesetzt
      newData.unshift({ b2b: 0, b2c: 0, interval: tmpDate.toISOString() })

      tmpDate.setDate(tmpDate.getDate() - 1)
    }

    setSalesAmazonDataInterval(convertDataStructure(newData))
  }

  const convertDataStructure = (inputData: Array<AmazonBuyerTypeSalesObject>) => {
    const outputData: Array<number> = []

    for (let i = 0; i < inputData.length; i++) {
      // Runden, da Float komische Sachen beim Runden macht
      const sum = inputData[i].b2b + inputData[i].b2c
      const roundedSum = sum.toFixed(2)
      outputData[i] = parseFloat(roundedSum)
    }
    return outputData
  }

  return (
    <Widget
      widget={widget}
      loading={loading}
      loadingSkeleton={<SalesSkeleton />}
      isError={error !== null}
      error={error}
      needsConfig={needsConfig}
      customOptions={
        <IntervalSelection
          intervals={intervals}
          key="interval-selection"
          settings={widget && widget.settings}
          onIntervalChange={onIntervalChange}
        />
      }
      image={{
        source:
          Platform.OS === 'web'
            ? require('../../../../../assets/imgs/widgets/amazon/amazon_logo.svg')
            : Image.resolveAssetSource(require('../../../../../assets/imgs/widgets/amazon/amazon_logo.png')).uri,
        alt: '',
      }}
      {...props}
      title={t('shop.widget.amazon.amazonumsatz.title')}
      hasInterval
      footerContent={
        <ColoredText>{(intervals.find(({ value }) => widget.settings.interval === value) || {}).label}</ColoredText>
      }
    >
      {([size]) => {
        return (
          <>
            <View>{error !== null ? '' : <AmazonAreaChart data={salesAmazonDataInterval} size={size} />}</View>
          </>
        )
      }}
    </Widget>
  )
}

export default AmazonSales
