import React from 'react'
import { Platform, Pressable, View } from 'react-native'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { useSecondaryBackground, useTextColor } from '../../../../../../hooks/useColors'
import { FontAwesomeIcon } from '../../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import colors from '../../../../../../constants/colors'
import useStyles from '../styles'

function calculateArrowAngle(presentValue: number | null, pastValue: number | undefined): number {
  if (pastValue === undefined || presentValue === null) return 0

  const maxDegree = -90
  const minDegree = 90
  const percentageChange = (100 / pastValue) * Math.abs(presentValue) - 100
  if (percentageChange >= 100) return maxDegree
  if (percentageChange <= -100) return minDegree

  const degree = (90 / 100) * percentageChange

  return -degree
}
function getArrowColor(presentValue: number | null, pastValue: number | undefined, textColor) {
  if (pastValue === undefined || presentValue === null) return textColor
  let arrowColor = colors.success
  const diff = pastValue - presentValue
  if (diff > 0) arrowColor = colors.error
  if (diff === 0) arrowColor = textColor
  return arrowColor
}

const Description = ({ title, icon, secondIcon }: { title: string; icon: IconProp | null; secondIcon: IconProp }) => {
  const style = useStyles()
  return (
    <View style={style.wrapper}>
      <View style={{ width: '85%' }}>
        <ColoredText style={{ margin: 5 }}>{title}</ColoredText>
      </View>
      <View style={style.rightWrapper}>
        {icon !== null && Platform.OS === 'web' && <FontAwesomeIcon color={colors.text} width={20} icon={icon} />}
        {/*@ts-expect-error [ToDo]: Describe Error */}
        {icon !== null && Platform.OS !== 'web' && <FontAwesomeIcon color={colors.text} size={20} icon={icon} />}

        {secondIcon !== null && Platform.OS === 'web' && (
          <FontAwesomeIcon color={colors.text} width={20} icon={secondIcon} />
        )}
        {secondIcon !== null && Platform.OS !== 'web' && (
          //@ts-expect-error [ToDo]: Describe Error
          <FontAwesomeIcon color={colors.text} size={20} icon={secondIcon} />
        )}
      </View>
    </View>
  )
}

const Top5Rows = ({
  title,
  value,
  secondValue,
  description = false,
  icon = null,
  secondIcon = null,
  percentage = 100,
  onPress,
}: {
  title: string
  value: number | null
  secondValue?: number | undefined
  description?: boolean
  icon?: IconProp | null
  secondIcon?: IconProp | null
  percentage?: number
  onPress?: () => void
}) => {
  const secondaryBackground = useSecondaryBackground()
  const textColor = useTextColor()
  const barColor = colors.color3
  const arrowColor = getArrowColor(value, secondValue, textColor)
  const style = useStyles()
  // const gradientColors =
  // theme === 'light'
  //     ? [colors.reportGradientDailyStart, colors.reportGradientDailyEnd]
  //     : [colors.reportGradientDailyEnd, colors.reportGradientDailyStart]
  if (description) return <Description icon={icon} secondIcon={secondIcon} title={title} />
  // console.log(`${secondValue} ${value} ${calculateArrowAngle(secondValue, value)}`)
  return (
    <Pressable onPress={onPress} style={[style.wrapper, Platform.OS === 'web' && { minHeight: 25 }]}>
      <View
        style={{
          height: '100%',
          width: `${percentage}%`,
          backgroundColor: barColor,
          borderBottomLeftRadius: 10,
          borderTopLeftRadius: 10,
          marginVertical: 5,
        }}
      >
        <ColoredText></ColoredText>
      </View>
      <View
        style={{
          height: '100%',
          width: `${100 - percentage}%`,
          backgroundColor: secondaryBackground,
          marginVertical: 5,
        }}
      >
        <ColoredText></ColoredText>
      </View>
      <ColoredText numberOfLines={1} style={{ position: 'absolute', left: 0, marginHorizontal: 5, width: '80%' }}>
        {title}
      </ColoredText>
      <View style={style.rightWrapper}>
        <ColoredText numberOfLines={1}>{value} </ColoredText>
        <View
          style={[
            {
              marginLeft: 5,
              transform: [{ rotate: `${calculateArrowAngle(value, secondValue)}deg` }],
            },
          ]}
        >
          <FontAwesomeIcon color={Number(percentage) < 90 ? arrowColor : textColor} icon="arrow-right" />
        </View>
      </View>
    </Pressable>
  )

  // return (
  // <Pressable
  //     onPr ess={() => url !== '' && openUrl(url)}
  //     st yle={{
  //     width: '100%',
  //     borderRadius: 10,
  //     marginBottom: 5,
  //     borderWidth: 2,
  //     borderColor: primaryBackground,
  //     alignItems: 'center',
  //     justifyContent: 'space-between',
  //     }}
  // >
  //     <LinearGradient
  //     colors={gradientColors}
  //     start={[0, 0]}
  //     end={[1, 0]}
  //     st yle={{ borderRadius: 8, width: '100%', flexDirection: 'row', overflow: 'hidden' }}
  //     >
  //     <View
  //         st yle={{
  //         backgroundColor: secondaryBackground,
  //         position: 'absolute',
  //         right: 0,
  //         height: '100%',
  //         width: `${100 - percentage}%`,
  //         }}
  //     />

  //     <ColoredText
  //         st yle={{ width: '80%', color: percentage > 80 ? rowTextColor : textColor, margin: 5 }}
  //         numberOfLines={1}
  //     >
  //         {title}
  //     </ColoredText>
  //     <ColoredText st yle={{ color: percentage > 80 ? rowTextColor : textColor, alignSelf: 'center', width: '20%' }}>
  //         {value}
  //     </ColoredText>
  //     </LinearGradient>
  //     <ColoredText
  //     ellipsizeMode="clip"
  //     st yle={{
  //         position: 'absolute',
  //         left: 0,
  //         paddingRight: 10,
  //         maxWidth: `${percentage}%`,
  //         color: 'white',
  //         margin: 5,
  //     }}
  //     numberOfLines={1}
  //     >
  //     {title}
  //     </ColoredText>
  // </Pressable>
  // )
}
export default Top5Rows
