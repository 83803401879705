import { StyleSheet } from 'react-native'
import { useSecondaryBackground, useTextColor } from '../../../../../hooks/useColors'
import { processFontFamily } from 'expo-font'
import colors from '../../../../../constants/colors'

const useStyles = () => {
  const textColor = useTextColor()
  return StyleSheet.create({
    invoiceCard: {
      backgroundColor: useSecondaryBackground(),
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'flex-end',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      padding: 10,
      marginHorizontal: 15,
      marginVertical: 5,
      borderRadius: 10,
    },
    title: {
      flexGrow: 2,
      overflow: 'hidden',
      marginBottom: 3,
      fontSize: 14,
      fontFamily: processFontFamily('Comfortaa_300Light') || undefined,
    },
    invoiceNumberText: {
      flexGrow: 2,
      overflow: 'hidden',
      fontSize: 22,
      fontFamily: processFontFamily('Comfortaa_400Regular') || undefined,
    },
    invoiceDateText: {
      flexGrow: 2,
      overflow: 'hidden',
      fontSize: 16,
      fontFamily: processFontFamily('Comfortaa_300Light') || undefined,
    },
    invoiceStateText: {
      flexGrow: 2,
      overflow: 'hidden',
      fontSize: 16,
      fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
    },
    invoiceAmountText: {
      overflow: 'hidden',
      fontSize: 20,
      fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
    },
    invoiceStateWrapper: {
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      borderRadius: 20,
      marginVertical: 10,
      marginTop: 30,
      borderWidth: 1,
      borderColor: textColor,
      padding: 3,
    },
    text: {
      flexGrow: 2,
      overflow: 'hidden',
      fontSize: 16,
      fontFamily: processFontFamily('Comfortaa_400Regular') || undefined,
    },
    actionWrapper: {
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'space-between',
      alignItems: 'flex-end',
    },
    deleteText: {
      color: colors.error,
      fontSize: 14,
      fontFamily: processFontFamily('Comfortaa_300Light') || undefined,
      marginRight: 10,
    },
  })
}

export default useStyles
