import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import Logo from '../Logo'
import useStyles from '../Login/styles'
import icons from '../../constants/icons'
import Button from '../Button'
import { useFirebase } from 'react-redux-firebase'
import { Toast } from 'native-base'
import { useTranslation } from '../../hooks/useTranslation'
import LoadingScreen from '../LoadingScreen'
import { Navigate } from '../Router/Router'
import { FontAwesomeIcon } from '../FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../CustomText/ColoredText'
import { getTextColor } from '../../hooks/useColors'
import { Themes } from '../../types/dashbar/theme'

export const EmailVerified = ({ oobCode }: { oobCode: string }) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const firebase = useFirebase()
  const [verify, setVerify] = useState<boolean>(true)
  const [verified, setVerified] = useState<boolean>(false)

  useEffect(() => {
    setVerify(true)
    firebase
      .auth()
      .applyActionCode(oobCode)
      .then(() => {
        setVerified(true)
      })
      .finally(() => {
        setVerify(false)
      })
  }, [oobCode])

  if (verify) {
    return <LoadingScreen />
  }
  if (!verified) {
    Toast.show({ title: 'Email could not be verified' })
    return <Navigate to={'/login'} />
  }
  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <Logo />

        <View style={[styles.hairLine]} />
        <View style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ margin: 5 }}>
            <FontAwesomeIcon icon={[icons.faIconStyle, 'check-circle']} size={'lg'} />
          </View>
          <View style={[styles.container, {}]}>
            <ColoredText style={{ textAlign: 'center', fontSize: 25 }}>
              <>{t('text.emailVerified.title')}</>
            </ColoredText>
            <ColoredText style={{ textAlign: 'center' }}>
              <>{t('text.emailVerified.text')}</>
            </ColoredText>
          </View>
        </View>
        <View style={[styles.hairLine]} />
        <View style={[styles.container]}>
          <Button to={'/'} style={styles.homeButton}>
            <FontAwesomeIcon color={getTextColor(Themes.Dark)} icon={[icons.faIconStyle, 'home']} />
          </Button>
        </View>
      </View>
    </View>
  )
}
