import { processFontFamily } from 'expo-font'
import { StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      padding: 15,
    },
    title: {
      fontSize: 18,
      fontFamily: processFontFamily('Comfortaa_400Regular') || undefined,
    },
  })
}

export default useStyles
