import { Linking, View } from 'react-native'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { SessionContext } from '../../../../context/SessionContext'
import Button from './../../../../components/Button'
import { usePrimaryBackground } from '../../../../hooks/useColors'
import LoadingScreen from '../../../../components/LoadingScreen'
import { Navigate } from '../../../../components/Router/Router'
import ColoredText from '../../../../components/CustomText/ColoredText'

const NoCompany = () => {
  const { t } = useTranslation()
  const { isLoaded, currentUser, signOut, user } = useContext(SessionContext)
  const bgColor = usePrimaryBackground()
  if (!isLoaded) {
    return <LoadingScreen />
  }

  if (isLoaded && !currentUser) {
    return <Navigate to={'/login'} />
  }

  if (isLoaded && user?.company) {
    return <Navigate to={'/'} />
  }

  return (
    <View style={{ flex: 1, justifyContent: 'space-around', alignItems: 'center' }}>
      <View style={{ backgroundColor: 'powderblue' }}>
        <View style={{ padding: 20, backgroundColor: bgColor }}>
          <ColoredText style={{ fontWeight: '700', fontSize: 16 }}>{t('title.noCompany')}</ColoredText>
          <View style={{ margin: 20, maxWidth: 480 }}>
            <ColoredText>{t('text.noCompany')}</ColoredText>
          </View>
          <View style={{ flex: 1, justifyContent: 'space-around', flexDirection: 'row' }}>
            <Button style={{ paddingHorizontal: 5 }} primary onPress={signOut}>
              {t('layout.logout')}
            </Button>

            <Button onPress={async () => Linking.openURL('https://ws-url.de/help?db=noCompany')}>Support</Button>
          </View>
        </View>
      </View>
    </View>
  )
}

export default NoCompany
