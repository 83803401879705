import { GestureResponderEvent, LayoutChangeEvent, TouchableOpacity, View } from 'react-native'
import AR_1 from './assets/AR_1'
import { useMatch, useNavigate } from 'react-router'
import React, { useContext, useEffect, useState } from 'react'
import AR_2 from './assets/AR_2'
import AR_3 from './assets/AR_3'
import AR_5 from './assets/AR_5'
import AR_8 from './assets/AR_8'
import AR_7 from './assets/AR_7'
import AR_6 from './assets/AR_6'
import AR_4 from './assets/AR_4'
import getApp from '../../firebase'
import { SessionContext } from '../../context/SessionContext'
import AR_Loading from './assets/AR_Loading'
import { Toast } from 'native-base'
import { addEasing } from '../../utils/animations'
import moment from 'moment'
import { reportValid } from './helper'

export const formatNumber = (value: number) => {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(0) + 'mio'
  } else if (value >= 1000) {
    return (value / 1000).toFixed(0) + 'k'
  }
  return value?.toString() ?? 0
}

const AnnualReview = () => {
  const year = useMatch(`annual_review/:year`)?.params?.year ?? 0
  const navigate = useNavigate()
  const [step, setStep] = useState<number>(0)
  const [shops, setShops] = useState<Array<string> | null>(null)
  const [report, setReport] = useState<Record<string, any> | null>(null)
  const [layout, setLayout] = useState({ x: 0, y: 0, width: 0, height: 0 })
  const { company } = useContext(SessionContext)
  const [selectedShop, setSelectedShop] = useState<string | null>(null)
  const [pageStats, setPageStats] = useState<Record<string, any>>({})

  const [startTime, setStartTime] = useState<Date | null>(null)

  useEffect(() => {
    if (company?.id && selectedShop !== null) {
      getApp()
        .firestore()
        .collection(`company/${company?.id}/shop/${selectedShop}/annual_review`)
        .doc(`${year}`)
        .get()
        .then((doc) => {
          if (doc.exists && reportValid(doc.data())) {
            setReport(doc.data())
            setPageStats(doc.data()?.z_analytics ?? {})
          } else {
            if (shops.length > 1) {
              setStep(0)
            } else {
              navigate(`/`)
            }

            Toast.show({ title: `Rückblick für den Shop ${selectedShop} nicht verfügbar!` })
            setReport(null)
          }
        })
    }
  }, [company?.id, selectedShop])

  useEffect(() => {
    getApp()
      .firestore()
      .collection(`company/${company?.id}/shop`)
      .get()
      .then((doc) => {
        if (doc.empty) {
          setShops([])
        } else {
          const shopArray: Array<string> = []
          doc.docs.forEach((shop) => {
            shopArray.push(shop.id)
          })
          setShops(shopArray)
          if (shopArray.length === 1) {
            setSelectedShop(shopArray[0])
          }
          console.log(shopArray)
        }
      })
  }, [company?.id])

  const stepForward = (shop?: string) => {
    trackStep(step)
    if (step >= 7) {
      onClose()
    } else {
      if (step === 0 && selectedShop === null && shop === undefined) return
      if (shop) setSelectedShop(shop)
      setStep(step + 1)
    }
  }
  const stepBackward = () => {
    trackStep(step)
    if (step > 0) {
      if (step === 1 && shops.length > 1) {
        setSelectedShop(null)
      }
      setStep(step - 1)
    } else {
      setStep(0)
    }
  }

  const handleStep = (event: GestureResponderEvent) => {
    addEasing()
    const { locationX } = event.nativeEvent
    const halfWidth = layout.width / 2
    if (locationX < halfWidth) {
      stepBackward()
    } else {
      stepForward()
    }
  }

  const handleLayout = (event: LayoutChangeEvent) => {
    const { x, y, width, height } = event.nativeEvent.layout
    setLayout({ x, y, width, height })
  }

  const selectShop = (shop: string) => {
    trackStep(step)
    setReport(null)
    stepForward(shop)
  }

  const trackStep = async (step: number) => {
    if (startTime === null) {
      setStartTime(new Date())
      return
    }
    const now = new Date()
    const diff = moment(now).diff(moment(startTime), 'second')
    const tempStats = pageStats
    tempStats[step] = {
      openings: (pageStats[step]?.openings ?? 0) + 1,
      time_spent: (pageStats[step]?.time_spent ?? 0) + diff,
    }
    setPageStats(tempStats)
    setStartTime(new Date())
  }

  const sendFeedback = async (liked: boolean) => {
    try {
      getApp()
        .firestore()
        .collection(`company/${company?.id}/shop/${selectedShop}/annual_review`)
        .doc(`${year}`)
        .set({ feedback: liked }, { merge: true })
    } catch {
      console.log(`Could not give Feedback`)
    }
  }

  const onClose = async () => {
    try {
      if (selectedShop && selectedShop !== null) {
        await getApp()
          .firestore()
          .collection(`company/${company?.id}/shop/${selectedShop}/annual_review`)
          .doc(`${year}`)
          .set({ z_analytics: pageStats }, { merge: true })
      }
      navigate(`/`)
    } catch {
      console.log(`Could not give Feedback`)
      navigate(`/`)
    }
  }

  return (
    <View
      style={{
        width: '100%',
        maxWidth: '100%',
        minWidth: '100%',
        height: '100%',
        minHeight: '100%',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        padding: -10,
        overflow: 'visible',
      }}
    >
      <TouchableOpacity
        onPress={handleStep}
        onLayout={handleLayout}
        activeOpacity={1}
        style={{
          padding: -10,
          overflow: 'visible',
          width: '100%',
          maxWidth: '100%',
          minWidth: '100%',
          height: '100%',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        {/* {report === null &&<AR_Loading />} */}
        {step === 0 && (
          <AR_1
            onClose={onClose}
            year={`${year}`}
            shops={shops}
            selectShop={selectShop}
            props={{ style: { backgroundColor: 'white' } }}
          />
        )}
        {step === 1 &&
          (report === null ? (
            <AR_Loading />
          ) : (
            <AR_2 onClose={onClose} year={`${year}`} report={report} props={{ style: { backgroundColor: 'white' } }} />
          ))}
        {step === 2 && <AR_3 onClose={onClose} report={report} props={{ style: { backgroundColor: 'white' } }} />}
        {step === 3 && <AR_4 onClose={onClose} report={report} props={{ style: { backgroundColor: 'white' } }} />}
        {step === 4 && (
          <AR_5 onClose={onClose} year={`${year}`} report={report} props={{ style: { backgroundColor: 'white' } }} />
        )}
        {step === 5 && (
          <AR_6 onClose={onClose} year={`${year}`} report={report} props={{ style: { backgroundColor: 'white' } }} />
        )}
        {step === 6 && <AR_7 onClose={onClose} report={report} props={{ style: { backgroundColor: 'white' } }} />}
        {step === 7 && (
          <AR_8
            onClose={onClose}
            year={+year}
            report={report}
            giveFeedback={sendFeedback}
            props={{ style: { backgroundColor: 'white' } }}
          />
        )}
      </TouchableOpacity>
    </View>
  )
}

export default AnnualReview
