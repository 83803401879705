import React, { lazy /* ReactElement*/ } from 'react'

import i18n from '../../locales/i18n'
import Shop from './pages/Main'
//import Analytics from './pages/Detailpages/Analytics'
// import Orders from './pages/Detailpages/Orders'
// import Order from './pages/Order'
// import LiveTracker from './pages/Detailpages/LiveTracker'
// import LiveVisitor from './pages/LiveVisitor'
// import OrderHeader from './pages/Order/Header'
// import OrdersHeader from './pages/Detailpages/Orders/Header'
// import LiveTrackerHeader from './pages/Detailpages/LiveTracker/Header'
// import LiveVisitorHeader from './pages/LiveVisitor/Header'
// import AnalyticsHeader from './pages/Detailpages/Analytics/Header'
// import ContingentWrapper from '../../components/ContingentWrapper'
// import Runner from './pages/Detailpages/Runner'
// import PagespeedHeader from './pages/Detailpages/Pagespeed/Header'
// import Pagespeed from './pages/Detailpages/Pagespeed'
// import AnalyticsMenu from './pages/Analytics'
// import Reports from './pages/Detailpages/Reports'
// import ReportHeader from './pages/Detailpages/Reports/Header'

const Orders = lazy(async () => import('./pages/Detailpages/Orders'))
const WawiOrders = lazy(async () => import('./pages/Detailpages/WawiOrders'))
const Order = lazy(async () => import('./pages/Detailpages/Order'))
const WawiOrder = lazy(async () => import('./pages/Detailpages/WawiOrder'))
const LiveTracker = lazy(async () => import('./pages/Detailpages/LiveTracker'))
const LiveVisitor = lazy(async () => import('./pages/LiveVisitor'))
const OrderHeader = lazy(async () => import('./pages/Detailpages/Order/Header'))
const OrdersHeader = lazy(async () => import('./pages/Detailpages/Orders/Header'))
const WawiOrdersHeader = lazy(async () => import('./pages/Detailpages/WawiOrders/Header'))
const LiveTrackerHeader = lazy(async () => import('./pages/Detailpages/LiveTracker/Header'))
const LiveVisitorHeader = lazy(async () => import('./pages/LiveVisitor/Header'))
const AnalyticsHeader = lazy(async () => import('./pages/Detailpages/Analytics/Header'))
const ContingentWrapper = lazy(async () => import('../../components/ContingentWrapper'))
const Runner = lazy(async () => import('./pages/Detailpages/Runner'))
const PagespeedHeader = lazy(async () => import('./pages/Detailpages/Pagespeed/Header'))
const Pagespeed = lazy(async () => import('./pages/Detailpages/Pagespeed'))
const AnalyticsMenu = lazy(async () => import('./pages/Analytics'))
const Reports = lazy(async () => import('./pages/Detailpages/Reports'))
const ReportHeader = lazy(async () => import('./pages/Detailpages/Reports/Header'))
const Analytics = lazy(async () => import('./pages/Detailpages/Analytics'))

const routes = () => [
  {
    title: i18n.t('nav.shop'),
    name: 'shops',
    route: {
      exact: true,
      path: '/shop',
      children: (
        <ContingentWrapper>
          <Shop />
        </ContingentWrapper>
      ),
    },
    requireAuth: true,
  },
  {
    title: i18n.t('nav.analytics'),
    name: 'analytics',
    route: {
      exact: true,
      path: '/analytics',
      children: (
        <ContingentWrapper>
          <AnalyticsMenu />
        </ContingentWrapper>
      ),
    },
    requireAuth: true,
  },
  {
    title: <OrdersHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.orders'), default: <OrdersHeader /> }),
    name: 'orders',
    route: {
      exact: true,
      path: '/analytics/orders/:shopId',
      children: (
        <ContingentWrapper>
          <Orders />
        </ContingentWrapper>
      ),
    },
    requireAuth: true,
  },
  {
    title: <OrdersHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.orders'), default: <OrdersHeader /> }),
    name: 'orders',
    route: {
      exact: true,
      path: '/analytics/orders',
      children: (
        <ContingentWrapper>
          <Orders />
        </ContingentWrapper>
      ),
    },
    requireAuth: true,
  },
  {
    title: <OrderHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.order'), default: <OrderHeader /> }),
    name: 'order',
    route: {
      exact: true,
      path: '/analytics/orders/:shopId/:orderId',
      children: (
        <ContingentWrapper>
          <Order />
        </ContingentWrapper>
      ),
    },
    requireAuth: true,
  },
  {
    title: <OrderHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.order'), default: <OrderHeader /> }),
    name: 'wawiorder',
    route: {
      exact: true,
      path: '/analytics/wawi/orders/:orderId',
      children: <WawiOrder />,
    },
    requireAuth: true,
  },
  {
    title: <WawiOrdersHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.order'), default: <OrderHeader /> }),
    name: 'wawiorders',
    route: {
      exact: true,
      path: '/analytics/wawi/orders',
      children: <WawiOrders />,
    },
    requireAuth: true,
  },
  {
    title: <AnalyticsHeader />, //Show FavoriteIcon also on Web -----old:Platform.select<ReactElement>({ web: i18n.t('nav.analytics'), default: <AnalyticsHeader /> }),
    name: 'analytics',
    route: {
      exact: true,
      path: '/analytics/analytics/:shopId',
      children: (
        <ContingentWrapper>
          <Analytics />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <AnalyticsHeader />, //Show FavoriteIcon also on Web -----old:Platform.select<ReactElement>({ web: i18n.t('nav.analytics'), default: <AnalyticsHeader /> }),
    name: 'analytics',
    route: {
      exact: true,
      path: '/analytics/analytics',
      children: (
        <ContingentWrapper>
          <Analytics />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <AnalyticsHeader />, //Show FavoriteIcon also on Web -----old:Platform.select<ReactElement>({ web: i18n.t('nav.analytics'), default: <AnalyticsHeader /> }),
    name: 'analytics',
    route: {
      exact: true,
      path: '/analytics/runner/:shopId',
      children: (
        <ContingentWrapper>
          <Runner />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <AnalyticsHeader />, //Show FavoriteIcon also on Web -----old:Platform.select<ReactElement>({ web: i18n.t('nav.analytics'), default: <AnalyticsHeader /> }),
    name: 'analytics',
    route: {
      exact: true,
      path: '/analytics/runner',
      children: (
        <ContingentWrapper>
          <Runner />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <LiveTrackerHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.live-tracker'), default: <LiveTrackerHeader /> }),
    name: 'live-tracker',
    route: {
      exact: true,
      path: '/analytics/live-tracker/:shopId',
      children: (
        <ContingentWrapper>
          <LiveTracker />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <LiveTrackerHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.live-tracker'), default: <LiveTrackerHeader /> }),
    name: 'live-tracker',
    route: {
      exact: true,
      path: '/analytics/live-tracker',
      children: (
        <ContingentWrapper>
          <LiveTracker />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <LiveVisitorHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.live-visitor'), default: <LiveVisitorHeader /> }),
    name: 'live-visitor',
    route: {
      exact: true,
      path: '/analytics/live-tracker/:shopId/:visitorId',
      children: (
        <ContingentWrapper>
          <LiveVisitor />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <PagespeedHeader />,
    name: 'pagespeed',
    route: {
      exact: true,
      path: '/analytics/pagespeed/:shopId',
      children: (
        <ContingentWrapper>
          <Pagespeed />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <PagespeedHeader />,
    name: 'pagespeed',
    route: {
      exact: true,
      path: '/analytics/pagespeed',
      children: (
        <ContingentWrapper>
          <Pagespeed />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <ReportHeader />,
    name: 'reports',
    route: {
      exact: true,
      path: '/analytics/reports/:type/:shopId',
      children: (
        <ContingentWrapper>
          <Reports />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <ReportHeader />,
    name: 'reports',
    route: {
      exact: true,
      path: '/reports/:type',
      children: (
        <ContingentWrapper>
          <Reports />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <ReportHeader />,
    name: 'reports',
    route: {
      exact: true,
      path: '/reports',
      children: (
        <ContingentWrapper>
          <Reports />
        </ContingentWrapper>
      ),
    },
  },
]

export default routes
