//START For transparent design
const darkBgTransparent1 = 'rgba(255,255,255, 0.1)'
const darkBgTransparent2 = 'rgba(255,255,255, 0.2)'
const darkBgTransparent4 = 'rgba(255,255,255, 0.4)'
const darkBgTransparent5 = 'rgba(255,255,255, 0.5)'
const darkBgTransparent6 = 'rgba(255,255,255, 0.6)'
const darkBgTransparent7 = 'rgba(255,255,255, 0.7)'
const darkBgTransparent8 = 'rgba(255,255,255, 0.8)'

const lightBgTransparent1 = 'rgba(0,0,0, 0.1)'
const lightBgTransparent2 = 'rgba(0,0,0, 0.2)'
const lightBgTransparent4 = 'rgba(0,0,0, 0.4)'
const lightBgTransparent6 = 'rgba(0,0,0, 0.6)'
const lightBgTransparent7 = 'rgba(0,0,0, 0.7)'
const lightBgTransparent8 = 'rgba(0,0,0, 0.8)'
//END For transparent design

const black = `#000`

const green_200 = `#C5E1A5`
const green_300 = `#AED581`
const green_800 = `#558B2F`

const red_200 = `#EF9A9A`
const red_300 = `#E57373`
const red_800 = `#C62828`

const grey_500 = `#9E9E9E`
const grey_700 = `#616161`

const error = '#b10000'
const success = '#33b100'
const text = '#858585'

const darkBg1 = '#0e0e1c' // usePrimaryBackground
const darkBg2 = '#1e2239' // useSecondaryBackground

const lightBg1 = '#f4f5f9' // usePrimaryBackground
const lightBg2 = '#ffffff' // useSecondaryBackground

const darkText1 = lightBg1 // useTextColor
const lightText1 = darkBg1 // useTextColor

const color1 = '#73157c' // purple
const color1_light = '#C7A1CA' //lighter purple
const color2 = '#2985d0' // blue
const color3 = '#f5a623' // orange

const color4 = '#0E0E1C' // very dark blue
const color5 = '#062844' // dark blue
const color6 = '#abaaa9' // yellow
const lightGray = '#E7E8EB'

const gradient1 = '#F55C5C'
const gradient2 = '#543FAC'

const bundleFree = color3
const bundleBusiness = color2
const bundlePro = color1

const highlightDark = color3 // useHighlightColor
const highlightLight = color1 // useHighlightColor

const highlightGreen = '#AECC53'
//START Gradients for report header area
const reportGradientDailyStart = '#2B85D1'
const reportGradientDailyEnd = '#73167B'

const reportGradientWeeklyStart = '#FA6A00'
const reportGradientWeeklyEnd = '#FFCD12'

const reportGradientMonthlyStart = '#1D2239'
const reportGradientMonthlyEnd = '#0C3150'

const reportGradientQuarterlyStart = '#4D0C53'
const reportGradientQuarterlyEnd = '#73167B'
//END Gradients for report header area

const headingColor = 'rgba(122, 122, 122,1)'

const linkColor = '#1c8fbd'

const pagespeed_average = darkBg1

const lightBlueColor = '#00C8FF'
export default {
  error,
  success,
  primary: color2,
  text,
  bundleFree,
  bundleBusiness,
  bundlePro,
  darkBg1,
  darkBg2,
  lightBg1,
  lightBg2,
  darkText1,
  lightText1,
  color1,
  color2,
  color3,
  color4,
  color5,
  lightBlueColor,
  highlightDark,
  highlightLight,
  darkBgTransparent1,
  darkBgTransparent2,
  darkBgTransparent4,
  darkBgTransparent5,
  darkBgTransparent6,
  darkBgTransparent7,
  darkBgTransparent8,
  lightBgTransparent1,
  lightBgTransparent2,
  lightBgTransparent4,
  lightBgTransparent6,
  lightBgTransparent7,
  lightBgTransparent8,
  headingColor,
  highlightGreen,
  gradient1,
  gradient2,
  reportGradientDailyStart,
  reportGradientDailyEnd,
  reportGradientWeeklyStart,
  reportGradientWeeklyEnd,
  reportGradientMonthlyStart,
  reportGradientMonthlyEnd,
  reportGradientQuarterlyStart,
  reportGradientQuarterlyEnd,
  pagespeed_average,
  linkColor,
  color1_light,
  color6,
  lightGray,
  green_200,
  green_300,
  green_800,
  red_200,
  red_300,
  red_800,
  black,
  grey_500,
  grey_700,
}
