import React, { useEffect } from 'react'
import { View, Image, Dimensions, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { AuthProvider as AuthProviderT, OAuthCredential as OAuthCredentialT } from '@firebase/auth-types'
import Logo from '../Logo'
import LoginForm from '../Form/LoginForm'
import AuthProvider from '../AuthProvider'
import useStyles from './styles'
import ColoredText from '../CustomText/ColoredText'
import { Link } from '../Router/Router'
import { processFontFamily } from 'expo-font'
import colors from '../../constants/colors'
import { useState } from 'react'
import AppStore from '../../assets/imgs/login/AppStore'
import { openUrl } from '../Browser'
import { TouchableOpacity } from 'react-native'
import PlayStore from '../../assets/imgs/login/PlayStore'

interface ILoginProps {
  alert?: { content: string; color?: 'error' | 'success' }
  loading: boolean
  handleLogin: (params: { email: string; password: string }) => void
  handleProviderLogin: (provider: AuthProviderT) => void
  handleCredentialLogin: (credential: OAuthCredentialT) => void
}

const Login = ({ alert, loading, handleLogin, handleProviderLogin, handleCredentialLogin }: ILoginProps) => {
  const { t } = useTranslation()

  const styles = useStyles()
  const [screenWidth, setScreenWidth] = useState<number>(Dimensions.get('window').width)

  const showImage = screenWidth > 1200
  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setScreenWidth(window.width)
    })
    return () => subscription?.remove()
  })

  return (
    <View style={styles.container}>
      <View style={styles.layoutWrapper}>
        <View style={styles.formWrapper}>
          <View style={styles.wrapper}>
            <View style={styles.center}>
              <View style={{ maxHeight: 75, marginLeft: 80 }}>
                <Logo color="black" />
              </View>
              <ColoredText style={{ color: 'black' }}>{t('text.baseLogin.welcome')}</ColoredText>
            </View>
            {alert && (
              <View style={styles.alert}>
                <ColoredText style={styles.alertText}>{alert.content}</ColoredText>
              </View>
            )}

            <View
              style={{
                paddingTop: 20,
              }}
            >
              <LoginForm onSubmit={handleLogin} loading={loading} />
              <AuthProvider
                handleProviderLogin={handleProviderLogin}
                handleCredentialLogin={handleCredentialLogin}
                loading={loading}
              />
              <View
                style={{
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  marginTop: 20,
                }}
              >
                <ColoredText
                  style={{
                    fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
                    fontSize: 12,
                    color: 'black',
                  }}
                >{`${t('text.baseLogin.noAccount')}`}</ColoredText>
                <Link to={'/signup'} style={{ marginBottom: 5, textDecorationLine: 'none' }}>
                  <ColoredText
                    style={{
                      fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
                      fontSize: 12,
                      color: colors.linkColor,
                    }}
                  >
                    {t('link.baseLogin.signup_dashbar')}
                  </ColoredText>
                </Link>
              </View>
            </View>
          </View>
          {Platform.OS === 'web' && (
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignContent: 'center',
                alignItems: 'center',
                marginTop: 15,
              }}
            >
              <ColoredText style={{ marginRight: 10, fontSize: 14 }}>{`${t(
                'text.baseSignup.install_app'
              )}`}</ColoredText>
              <TouchableOpacity onPress={async () => openUrl(`https://apps.apple.com/us/app/dash-bar/id1439795569`)}>
                <AppStore />
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginLeft: 10 }}
                onPress={async () => openUrl(`https://play.google.com/store/apps/details?id=bar.dash.app&hl=de`)}
              >
                <PlayStore />
              </TouchableOpacity>
            </View>
          )}
        </View>
        {showImage === true && (
          <>
            <View style={styles.spacer} />
            <View>
              <Image
                {...{
                  height: 550,
                  width: 300,
                  resizeMode: 'contain',
                  source: { uri: 'https://ws-cdn.de/dashbar/landingpage.png' },
                  alt: 'Not found',
                  style: {
                    height: 550,
                    width: 300,
                  },
                }}
              />
            </View>
          </>
        )}
      </View>
    </View>
  )
}

export default Login
