import React, { useState } from 'react'
import { StyleSheet, TextInput, TextInputProps, View, ViewStyle } from 'react-native'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '../FontAwesome/ColoredFontAwesomeIcon'
import { processFontFamily } from 'expo-font'
import colors from '../../constants/colors'
import ColoredText from '../CustomText/ColoredText'
import { useTextColor } from '../../hooks/useColors'

const useStyles = () => {
  const textColor = useTextColor()
  return StyleSheet.create({
    input: {
      height: 30,
      paddingHorizontal: 5,
      flex: 1,
      //@ts-expect-error not accepted in react-native
      outlineStyle: 'none',
      color: textColor,
      fontFamily: processFontFamily('Comfortaa_300Light') || undefined,
      fontSize: 16,
      overflow: 'hidden',
    },
    wrapper: {
      display: 'flex',
      padding: 7,
      width: '100%',
      borderWidth: 1,
      borderRadius: 10,
      borderColor: 'black',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'flex-start',
      overflow: 'hidden',
    },
    outerWrapper: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      justifyContent: 'flex-start',
    },
    focused: {
      borderWidth: 2,
    },
    disabled: {
      opacity: 0.7,
    },
    error: {
      borderColor: colors.error,
      borderWidth: 2,
    },
    errorText: {
      paddingHorizontal: 5,
      fontFamily: processFontFamily('Comfortaa_300Light') || undefined,
      color: colors.error,
    },
  })
}

const InputWithIcon = ({
  icon,
  refForward,
  style,
  viewStyle,
  defaultColor = 'black',
  editable,
  hasError = false,
  errorMsg,
  ...props
}: TextInputProps & {
  icon: IconProp
  refForward?: (ref: TextInput) => void
  viewStyle?: ViewStyle
  defaultColor?: string
  hasError?: boolean
  errorMsg?: string
}) => {
  const styles = useStyles()
  const [focused, setFocused] = useState<boolean>(false)

  return (
    <View style={styles.outerWrapper}>
      <View
        style={[
          styles.wrapper,
          viewStyle,
          focused === true ? styles.focused : null,
          hasError === true ? styles.error : null,
        ]}
      >
        <FontAwesomeIcon
          //@ts-expect-error not accepted in react-native
          icon={['fas', icon]}
          color={defaultColor}
          style={{
            marginLeft: 4,
            marginRight: 5,
            color: defaultColor,
            ...(editable === false && { opacity: 0.7 }),
          }}
        />
        <TextInput
          ref={refForward}
          style={[styles.input, style, editable === false && styles.disabled]}
          editable={editable}
          {...props}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholderTextColor={defaultColor}
        />
      </View>
      {hasError === true && errorMsg !== undefined && <ColoredText style={styles.errorText}>{errorMsg}</ColoredText>}
    </View>
  )
}

export default InputWithIcon
