import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Dialog } from 'react-native-paper'
import MobileDialog from '../../../components/MobileDialog/MobileDialog'
import { useSecondaryBackground } from '../../../hooks/useColors'
import useDeviceType from '../../../hooks/useDeviceType'
import ShopSelectionDialogBody from './ShopSelectionDialogBody'

const NotificationFilterDialog = ({
  shops,
  isVisible,
  filter,
  setisVisible,
  updateFilter,
}: {
  shops: Array<string>
  isVisible: boolean
  filter: { shopList: Array<string>; notificationType: Array<string> }
  setisVisible: React.Dispatch<React.SetStateAction<boolean>>
  updateFilter: (value: string, updateCategory: string) => void
}) => {
  const device = useDeviceType()
  const secondaryBackground = useSecondaryBackground()
  if (device === 'mobile') {
    return (
      <MobileDialog isVisible={isVisible} showDialog={setisVisible}>
        <ShopSelectionDialogBody shops={shops} filter={filter} updateFilter={updateFilter} />
      </MobileDialog>
    )
  }
  return (
    <Dialog
      style={{
        position: 'absolute',
        top: 40,
        alignSelf: 'center',
        backgroundColor: secondaryBackground,
      }}
      visible={isVisible}
      onDismiss={() => setisVisible(false)}
    >
      <Dialog.Content>
        <TouchableOpacity onPress={() => setisVisible(false)}>
          <FontAwesomeIcon style={{ alignSelf: 'flex-end' }} icon={'times'}></FontAwesomeIcon>
        </TouchableOpacity>
        <ShopSelectionDialogBody shops={shops} filter={filter} updateFilter={updateFilter} />
      </Dialog.Content>
    </Dialog>
  )
}
export default NotificationFilterDialog
